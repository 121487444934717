import { customElement, property } from 'lit/decorators.js';
import { LitElement } from 'lit';
import { ScriptLoaderMap } from './script-singleton-loader.js';

abstract class ScriptLibraryElement extends LitElement {
  public libraryLoaded = false;

  public libraryErrorMessage: string | null = null;
  private isReady = false;

  abstract get libraryUrl(): string;

  abstract get notifyEvent(): string;

  get callbackName(): string | null {
    return null;
  }

  libraryUrlChanged() {
    // can't load before ready because notifyEvent might not be set
    if (this.isReady && this.libraryUrl != null) this.loadLibrary();
  }

  libraryLoadCallback(detail: any, error?: Error) {
    if (error) {
      console.warn('Library load failed:', error.message);
      this.libraryErrorMessage = error.message;
    } else {
      this.libraryErrorMessage = null;
      this.libraryLoaded = true;
      if (this.notifyEvent != null) {
        this.dispatchEvent(new CustomEvent(this.notifyEvent, { detail: detail, composed: true }));
      }
    }
  }

  /** loads the library, and fires this.notifyEvent upon completion */
  loadLibrary() {
    ScriptLoaderMap.getInstance().require(
      this.libraryUrl,
      notifyCallback => this.libraryLoadCallback(notifyCallback),
      this.callbackName ?? ''
    );
  }

  connectedCallback() {
    super.connectedCallback();

    this.isReady = true;
    if (this.libraryUrl != null) this.loadLibrary();
  }
}

@customElement('google-maps-api')
export class GoogleMapsApi extends ScriptLibraryElement {
  @property({ type: String, attribute: 'api-key' })
  apiKey = '';

  @property({ type: String, attribute: 'maps-url' })
  mapsUrl = 'https://maps.googleapis.com/maps/api/js?callback=%%callback%%';

  @property({ type: String })
  version = '';

  @property({ type: String })
  language = '';

  @property({ type: String, attribute: 'map-id' })
  mapId = '';

  get libraryUrl(): string {
    return this.computeUrl(this.mapsUrl, this.version, this.apiKey, this.language, this.mapId);
  }

  get notifyEvent(): string {
    return 'api-load';
  }

  computeUrl(mapsUrl: string, version: string, apiKey: string, language: string, mapId: string): string {
    let url = mapsUrl + '&v=' + version;

    // Always load all Maps API libraries.
    url += '&libraries=drawing,geometry,places,visualization,marker';

    if (apiKey) {
      url += '&key=' + apiKey;
    }

    // Log a warning if the user is not using an API Key or Client ID.
    if (!apiKey) {
      const warning =
        'No Google Maps API Key or Client ID specified. ' +
        'See https://developers.google.com/maps/documentation/javascript/get-api-key ' +
        'for instructions to get started with a key or client id.';
      console.warn(warning);
    }

    if (language) {
      url += '&language=' + language;
    }

    if (mapId) {
      url += '&map_ids=' + mapId;
    }

    return url;
  }
}
