import { ChartDataXY, ChartSeriesXY } from '../data/chart-data-types';
import { customElement, property } from 'lit/decorators.js';
import { DashboardWidget } from './dashboard-widget';
import { DateRange } from './dashboard-filter';
import { generatedPurchaseOrdersCache } from '../data/generated-purchase-orders-cache';
import { getPurchaseOrderStatusStr } from '../../../purchase-orders/data/purchase-order-helper-functions';
import { html, PropertyValues } from 'lit';
import {
  localDateTimeToServer,
  serverDateTimeToLocalDateTime
} from '../../../../webmodule-common/other/datetime-converter';
import {
  PurchaseOrderState,
  ResultGetGeneratedPurchaseOrderRevenue,
  ViewDailyRevenue
} from '../../../api/dealer-api-interface-franchisee';
import { TimelineWidgetModal } from './timeline-widget-modal';
import { tlang } from '../../../../webmodule-common/other/language/lang';
import type { NullPromise, Snippet } from '../../../../webmodule-common/interop/webmodule-interop';

const ApexCharts = (globalThis as any).ApexCharts;

@customElement('generated-purchase-orders-revenue-widget')
export class GeneratedPurchaseOrdersWidget extends DashboardWidget {
  chart: any | null = null;

  @property({ type: Object }) dateRange?: DateRange;
  @property() branchId?: string;
  @property() userId?: string;
  @property({ type: Array }) states: PurchaseOrderState[] = [
    PurchaseOrderState.Draft,
    PurchaseOrderState.IssuedPending,
    PurchaseOrderState.Issued,
    PurchaseOrderState.Completed
  ];

  private _cache = generatedPurchaseOrdersCache();

  protected async refreshData(): NullPromise<ResultGetGeneratedPurchaseOrderRevenue> {
    if (this.dateRange) {
      const startDate = localDateTimeToServer(this.dateRange.startDate);
      const endDate = localDateTimeToServer(this.dateRange.endDate);
      const result = await this._cache?.getData(startDate, endDate, this.states, this.branchId, this.userId);
      if (result) {
        return result;
      }
    }
    return null;
  }

  protected firstUpdated(): void {
    const options = {
      chart: {
        type: 'area',
        height: '100%',
        selection: {
          enabled: false
        },
        stacked: false,
        toolbar: {
          tools: {
            download: false,
            customIcons: [
              {
                icon: '<i class="fa fa-plus-circle fa-lg"></i>',
                index: 0,
                title: 'Zoom',
                class: 'custom-icon',
                click: (chart: any, options: any, e: any) => this.onChartClick(e, chart, options)
              }
            ]
          }
        },
        zoom: {
          enabled: false
        }
      },
      stroke: {
        width: 1
      },
      dataLabels: {
        enabled: false
      },
      legend: { show: false },
      tooltip: {
        enabled: false
      },
      states: {
        hover: {
          filter: {
            type: 'none',
            value: 0
          }
        },
        active: {
          filter: {
            type: 'none',
            value: 0
          }
        }
      },
      series: [],
      xaxis: {
        type: 'datetime'
      },
      yaxis: {
        labels: {
          formatter: (value: number) => this.getYAxisLabel(value)
        }
      },
      noData: {
        text: 'Loading...'
      }
    };

    this.chart = new ApexCharts(this.querySelector('.purchase-order-issued-revenue'), options);
    this.chart.render();
  }

  protected updated(changedProperties: PropertyValues) {
    super.updated(changedProperties);
    this.updateData();
  }

  private getTitle(): string {
    return tlang`!!purchase-order!! Revenue (Net Amount) - ${this.dateRange?.label}`;
  }

  protected getHeader(): Snippet {
    return this.getTitle();
  }

  protected getBody(): Snippet {
    return html`<div class="purchase-order-issued-revenue"></div>`;
  }

  protected getYAxisLabel(value: number): string {
    return `$${Intl.NumberFormat('en-US', {
      notation: 'compact',
      maximumFractionDigits: 1
    }).format(value)}`;
  }

  protected async onChartClick(_event: any, _chartContext: any, config: any) {
    const series = config.config.series as ChartSeriesXY[];
    const modal = new TimelineWidgetModal();
    modal.eventTitle = this.getTitle();
    modal.seriesData = series;
    await modal.showModal();
  }

  protected convertDateToMillis(dateStr: string): number {
    let dt = serverDateTimeToLocalDateTime(dateStr);
    dt = dt.set({ hour: 12, minute: 0 });
    return dt.toMillis();
  }

  protected getSeriesColorByState(state: number): string {
    // return the corresponding badge colour
    switch (state as PurchaseOrderState) {
      case PurchaseOrderState.Draft:
        return '#0ea5e9';
      case PurchaseOrderState.IssuedPending:
        return '#a855f7';
      case PurchaseOrderState.Issued:
        return '#eab308';
      case PurchaseOrderState.Completed:
        return '#52525b';
      default:
        return '#3282cc'; // base colour
    }
  }

  protected updateData(): void {
    this.refreshData().then(results => {
      if (results) {
        const chartSeries: ChartSeriesXY[] = [];

        for (const quoteState of results.purchaseOrderStates) {
          const chartData: ChartDataXY[] = [];
          const data = results.revenueSummary.filter(d => d.state === quoteState || d.state === -1);
          data.map(r =>
            chartData.push({
              x: this.convertDateToMillis(r.date),
              y: r.dailyRevenue
            })
          );
          chartSeries.push({
            name: tlang`${getPurchaseOrderStatusStr(quoteState)}`,
            data: chartData,
            color: this.getSeriesColorByState(quoteState)
          });
        }

        this.chart.updateSeries(chartSeries, true);
      }
    });
  }
}
