import { Address, NullPromise } from '../../../../webmodule-common/interop/webmodule-interop';
import {
  AddressEditor,
  clone,
  emptyAddress,
  emptyGuid,
  FormInputAssistant,
  FormInputAssistantBase,
  isAddressNullOrEmpty
} from '../../../../webmodule-common/other/webmodule-common';
import { buttonsSaveCancel } from '../../../../webmodule-common/other/ui/modal-confirmation';
import { cache } from '../../cache-impl/cache-registry';
import { customElement, property, state } from 'lit/decorators.js';
import { DataBinding } from '../../../../webmodule-common/other/ui/databinding/databinding';
import { DataTracker, FieldType } from '../../../../webmodule-common/other/ui/databinding/data-tracker';
import { EventTemplate, Snippet } from '../../../../webmodule-common/other/ui/events';
import { getQuoteStatusStr } from '../../../quotes/data/quote-helper-functions';
import { html, TemplateResult } from 'lit';
import { isEmptyOrSpace } from '../../../../webmodule-common/other/ui/string-helper-functions';
import { ModalDialog } from '../../../../webmodule-common/other/ui/modal-base';
import { ProjectPickerBrowser } from '../../../projects/views/project-picker';
import { ProjectState, ViewProjectSummary } from '../../../api/dealer-api-interface-project';
import { QuoteState } from '../../../api/dealer-api-interface-quote';
import { showValidations } from '../../../../webmodule-common/other/ui/modal-validationhandler';
import { tlang } from '../../../../webmodule-common/other/language/lang';
import { userDataStore } from '../../common/current-user-data-store';
import {
  WebmoduleChangeEvent,
  WebmoduleRadioGroup
} from '../../../../webmodule-common/components/src/webmodule-components';

export interface FranchiseeQuoteCreateProjectModalOptions {
  clientId: string;
  description: string;
  defaultAddress: Address;
  shippingNotes: string | null;
}

@customElement('wm-franchiseequotecreateprojectmodal')
export class FranchiseeQuoteCreateProjectModal extends ModalDialog {
  protected readonly dataBinding: DataBinding;
  protected readonly dataTracker: DataTracker;
  protected readonly clientId: string;
  protected readonly projectPicker: ProjectPickerBrowser;

  @property({ type: String })
  private _projectTitle: string = '';
  public get projectTitle(): string {
    return this._projectTitle;
  }
  protected set projectTitle(value: string) {
    this._projectTitle = value;
  }

  @property({ type: String })
  private _description: string = '';
  public get description(): string {
    return this._description;
  }
  protected set description(value: string) {
    this._description = value;
  }

  @property({ type: Object })
  private _projectAddress: Address;
  public get projectAddress(): Address {
    return this._projectAddress;
  }
  protected set projectAddress(value: Address) {
    this._projectAddress = value;
  }

  @property({ type: String })
  private _shippingNotes: string | null;
  public get shippingNotes(): string | null {
    return this._shippingNotes;
  }
  protected set shippingNotes(value: string | null) {
    this._shippingNotes = value;
  }

  @property({ type: Object })
  private _project: ViewProjectSummary | undefined;
  public get project(): ViewProjectSummary | undefined {
    return this._project;
  }
  protected set project(value: ViewProjectSummary) {
    this._project = value;
  }

  @state()
  private _ok: boolean = false;
  public get ok(): boolean {
    return this._ok;
  }
  protected set ok(value: boolean) {
    this._ok = value;
  }

  private _needsProjectCreate: boolean = true;
  public get needsProjectCreate(): boolean {
    return this._needsProjectCreate;
  }
  protected set needsProjectCreate(value: boolean) {
    this._needsProjectCreate = value;
  }

  constructor(options: FranchiseeQuoteCreateProjectModalOptions) {
    super();
    this.dataBinding = new DataBinding(this.ui);
    this.dataTracker = new DataTracker(this.dataBinding);

    this.clientId = options.clientId;
    this.description = options.description;
    this.projectAddress = options.defaultAddress;
    this.shippingNotes = options.shippingNotes;

    this.projectPicker = this.getPickerFactory();

    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };

    addField('projectTitle');
    addField('description');
  }

  public async afterConstruction(): Promise<void> {
    await super.afterConstruction();

    await this.projectPicker.refreshData();
  }

  protected getTitle(): Snippet {
    return tlang`New %%project%%`;
  }

  protected getPickerFactory(): ProjectPickerBrowser {
    return new ProjectPickerBrowser({
      projectStates: ProjectState.Active,
      clientCache: cache().client,
      userCache: cache().userProfile,
      purchaseOrderCache: cache().purchaseOrder,
      title: this.getTitle,
      // eslint-disable-next-line @typescript-eslint/require-await
      projectOwnerId: async () => userDataStore.defaultBranch.id,
      pageFragment: '',
      onProjectSelected: (projectSummary: ViewProjectSummary) => this.onProjectSelected(projectSummary),
      selectedProject: this.project,
      clientId: this.clientId
    });
  }

  protected bodyTemplate(): EventTemplate {
    const forms = new FormInputAssistant(this.dataTracker);

    const createRadioButton = (text: string, input: boolean) => {
      return html` <webmodule-radio value="${input}">${text}</webmodule-radio>`;
    };

    const selector = html`
      <webmodule-radio-group
        name="filtergroup-projectSetupType"
        class="quote-project-setup-filter"
        value="${this.needsProjectCreate}"
        inline
        size="small"
        @webmodule-change=${(e: WebmoduleChangeEvent) => this.onProjectSetupModeChanged(e)}
      >
        ${createRadioButton(tlang`New %%project%%`, true)}
        ${createRadioButton(tlang`Add to existing %%project%%`, false)}
      </webmodule-radio-group>
    `;

    return html`
      <form class="form-one-col">
        <div class="row">
          <div class="form-column">
            <h3>
              ${tlang`When a %%quote%%'s status progresses from ${getQuoteStatusStr(QuoteState.Draft)} to ${getQuoteStatusStr(QuoteState.Active)}, the system will generate a %%project%% to contain all relevant documentation. Alternatively, you may add the %%quote%% to an existing %%project%%.`}
            </h3>
            ${selector}
            <div class="quote-project-setup-content">
              ${this.needsProjectCreate ? this.createProjectTemplate(forms) : this.linkToExistingProjectTemplate()}
            </div>
          </div>
        </div>
      </form>
    `;
  }

  protected createProjectTemplate(forms: FormInputAssistant): TemplateResult {
    const addressTemplate = forms.addressEditor(
      tlang`%%project%% (Site Installation) Address`,
      'projectAddressEditor',
      {
        address: this.projectAddress,
        onAddressChanged: (e: Event) => this.addressModified(e.currentTarget as AddressEditor),
        displayMap: false
      }
    );

    return html`
      ${forms.textRequired('projectTitle', tlang`%%project%% Title`, 120)}
      ${forms.note('description', tlang`Description`, 2950)} ${addressTemplate}
    `;
  }

  protected linkToExistingProjectTemplate(): TemplateResult {
    return html`
      <div>${tlang`Only !!project!! with a matching %%client%% appear in the list below.`}</div>
      ${this.projectPicker}
    `;
  }

  protected footerTemplate(): TemplateResult | null {
    const okEvent = async () => {
      this.dataTracker.applyChangeToValue();
      const errors = await this.getValidationErrors();
      if (errors.length > 0) {
        this.ok = false;
        await showValidations(errors);
      } else {
        this.ok = true;
        await this.hideModal();
      }
    };
    const cancelEvent = async () => {
      this.projectTitle = '';
      this.ok = false;
      await this.hideModal();
    };
    const buttons = buttonsSaveCancel();
    return this.createConfirmCancelButtons(buttons.ok, okEvent, buttons.cancel, cancelEvent);
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  protected async getValidationErrors(): Promise<string[]> {
    const errors: string[] = [];

    if (this.needsProjectCreate) {
      if (isEmptyOrSpace(this.dataBinding.getValue('projectTitle'))) {
        errors.push(tlang`Cannot have an empty Title for the %%project%%`);
      }
      if (isAddressNullOrEmpty(this.projectAddress)) {
        errors.push(tlang`Cannot have an empty Address for the %%project%%`);
      }
    } else {
      if (!this.project) {
        errors.push(tlang`Please select a %%project%% to add this %%quote%% to.`);
      }
    }

    return errors;
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  protected async addressModified(addressEditor: AddressEditor) {
    if (addressEditor.address) {
      this.projectAddress = clone(addressEditor.address);
      this.shippingNotes = addressEditor.shippingNotes ?? null;
    } else {
      this.projectAddress = emptyAddress();
      this.shippingNotes = '';
    }
  }

  protected onProjectSelected(projectSummary: ViewProjectSummary): void {
    this.project = projectSummary;
  }

  protected onProjectSetupModeChanged(e: WebmoduleChangeEvent) {
    const target = e.target as WebmoduleRadioGroup | null;

    if (target === null) return;

    const groupValue = target.value;

    this.needsProjectCreate = groupValue === 'true';

    this.requestUpdate();
  }
}
