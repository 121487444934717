import { compare } from '../../../../webmodule-common/other/clone';
import { emptyGuid } from '../../../../webmodule-common/other/api/guid';
import { getApiFactory } from '../../../api/api-injector';
import { ResultGetIssuedQuoteRevenue, ViewDailyRevenue } from '../../../api/dealer-api-interface-quote';

class IssuedQuotesCache {
  _pageKey = this.pageKey('', '-1', []);
  _pagePromise: Promise<ResultGetIssuedQuoteRevenue | null> | null = null;
  _issuedQuoteRevenue: ViewDailyRevenue[] = [];
  pageKey(
    startDate: string,
    endDate: string,
    quoteStates: number[],
    branchId?: string,
    userId?: string
  ): { startDate: string; endDate: string; quoteStates: number[]; branchId?: string; userId?: string } {
    return { startDate, endDate, quoteStates, branchId, userId };
  }
  async getData(startDate: string, endDate: string, quoteStates: number[], branchId?: string, userId?: string) {
    const currentPage = this.pageKey(startDate, endDate, quoteStates, branchId, userId);
    if (!compare(this._pageKey, currentPage)) {
      this._pageKey = currentPage;
      this._pagePromise = getApiFactory()
        .quote()
        .getIssuedQuoteRevenue({
          startDate: startDate,
          endDate: endDate,
          quoteStates: quoteStates,
          branchId: branchId ?? emptyGuid,
          userId: userId ?? emptyGuid
        });
    }
    return await this._pagePromise;
  }
}

let _db: IssuedQuotesCache | null = null;
export function flushIssuedQuotesCache() {
  _db = null;
}
export function issuedQuotesCache(): IssuedQuotesCache {
  if (!_db) {
    _db = new IssuedQuotesCache();
  }
  return _db;
}
