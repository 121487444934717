import { classMap } from 'lit/directives/class-map.js';
import { defaultValue } from '../../common/default-value.js';
import { html } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
import { live } from 'lit/directives/live.js';
import { monitor } from '../../common/monitor.js';
import { property, query, state } from 'lit/decorators.js';
import componentStyles from '../../styles/component.styles.js';
import formControlStyles from '../../styles/form-control.styles.js';
import styles from './checkbox.styles.js';
import WebModuleElement from '../../common/webmodule-element.js';
import type { CSSResultGroup } from 'lit';

/**
 * @summary Checkboxes allow the user to toggle an option on or off.
 *
 * @slot - The checkbox's label.
 *
 * @event webmodule-blur - Emitted when the checkbox loses focus.
 * @event webmodule-change - Emitted when the checked state changes.
 * @event webmodule-focus - Emitted when the checkbox gains focus.
 * @event webmodule-input - Emitted when the checkbox receives input.
 *
 * @csspart base - The component's base wrapper.
 * @csspart control - The square container that wraps the checkbox's checked state.
 * @csspart control--checked - Matches the control part when the checkbox is checked.
 * @csspart control--indeterminate - Matches the control part when the checkbox is indeterminate.
 * @csspart checked-icon - The checked icon, an `<webmodule-icon>` element.
 * @csspart indeterminate-icon - The indeterminate icon, an `<webmodule-icon>` element.
 * @csspart label - The container that wraps the checkbox's label.
 *
 * @tag webmodule-checkbox
 */
export default class WebmoduleCheckbox extends WebModuleElement {
  static styles: CSSResultGroup = [componentStyles, formControlStyles, styles];
  @query('input[type="checkbox"]') input: HTMLInputElement;
  /** Draws the checkbox in a checked state. */
  @property({ type: Boolean, reflect: true }) checked = false;
  /**
   * Draws the checkbox in an indeterminate state.
   */
  @property({ type: Boolean, reflect: true }) indeterminate = false;
  /** The default value of the form control. Primarily used for resetting the form control. */
  @defaultValue('checked') defaultChecked = false;
  @property() title = '';
  /** The name of the checkbox, submitted as a name/value pair with form data. */
  @property() name = '';
  /** The current value of the checkbox, submitted as a name/value pair with form data. */
  @property() value = '';
  /** The checkbox's size. */
  @property({ reflect: true }) size: 'small' | 'medium' | 'large' = 'medium';
  /** Disables the checkbox. */
  @property({ type: Boolean, reflect: true }) disabled = false;
  /** Makes the checkbox a required field. */
  @property({ type: Boolean, reflect: true }) required = false;

  @state() private hasFocus = false;

  @monitor(['checked', 'indeterminate'], { delayMonitorUntilFirstUpdate: true })
  handleStateChange() {
    this.input.checked = this.checked; // force a sync update
    this.input.indeterminate = this.indeterminate; // force a sync update
  }

  click() {
    this.input.click();
  }

  focus(options?: FocusOptions) {
    this.input.focus(options);
  }

  blur() {
    this.input.blur();
  }

  render() {
    return html`
      <div
        class=${classMap({
          'form-control': true,
          'form-control--small': this.size === 'small',
          'form-control--medium': this.size === 'medium',
          'form-control--large': this.size === 'large'
        })}
      >
        <label
          part="base"
          class=${classMap({
            checkbox: true,
            'checkbox--checked': this.checked,
            'checkbox--disabled': this.disabled,
            'checkbox--focused': this.hasFocus,
            'checkbox--indeterminate': this.indeterminate,
            'checkbox--small': this.size === 'small',
            'checkbox--medium': this.size === 'medium',
            'checkbox--large': this.size === 'large'
          })}
        >
          <input
            class="checkbox__input"
            type="checkbox"
            title=${this.title}
            name=${this.name}
            value=${ifDefined(this.value)}
            .indeterminate=${live(this.indeterminate)}
            .checked=${live(this.checked)}
            .disabled=${this.disabled}
            .required=${this.required}
            aria-checked=${this.checked ? 'true' : 'false'}
            @click=${this.handleClick}
            @input=${this.handleInput}
            @blur=${this.handleBlur}
            @focus=${this.handleFocus}
          />

          <span
            part="control${this.checked ? ' control--checked' : ''}${this.indeterminate
              ? ' control--indeterminate'
              : ''}"
            class="checkbox__control"
          >
            ${this.checked
              ? html`
                  <webmodule-icon
                    part="checked-icon"
                    class="checkbox__checked-icon"
                    library="default"
                    name="check"
                  ></webmodule-icon>
                `
              : ''}
            ${!this.checked && this.indeterminate
              ? html`
                  <webmodule-icon
                    part="indeterminate-icon"
                    class="checkbox__indeterminate-icon"
                    library="default"
                    name="indeterminate"
                  ></webmodule-icon>
                `
              : ''}
          </span>

          <div part="label" class="checkbox__label">
            <slot></slot>
          </div>
        </label>
      </div>
    `;
  }

  private handleClick() {
    this.checked = !this.checked;
    this.indeterminate = false;
    this.emit('webmodule-change');
  }

  private handleBlur() {
    this.hasFocus = false;
    this.emit('webmodule-blur');
  }

  private handleInput() {
    this.emit('webmodule-input');
  }

  private handleFocus() {
    this.hasFocus = true;
    this.emit('webmodule-focus');
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'webmodule-checkbox': WebmoduleCheckbox;
  }
}
