import { compare } from '../../../../webmodule-common/other/clone';
import { emptyGuid } from '../../../../webmodule-common/other/api/guid';
import { getApiFactory } from '../../../api/api-injector';
import { ResultGetGeneratedPurchaseOrderRevenue } from '../../../api/dealer-api-interface-franchisee';

export class GeneratedPurchaseOrdersCache {
  _pageKey = this.pageKey('', '-1', []);
  _pagePromise: Promise<ResultGetGeneratedPurchaseOrderRevenue | null> | null = null;
  pageKey(
    startDate: string,
    endDate: string,
    purchaseOrderStates: number[],
    branchId?: string,
    userId?: string
  ): { startDate: string; endDate: string; purchaseOrderStates: number[]; branchId?: string; userId?: string } {
    return { startDate, endDate, purchaseOrderStates, branchId, userId };
  }
  async getData(startDate: string, endDate: string, purchaseOrderStates: number[], branchId?: string, userId?: string) {
    const currentPage = this.pageKey(startDate, endDate, purchaseOrderStates, branchId, userId);
    if (!compare(this._pageKey, currentPage)) {
      this._pageKey = currentPage;
      this._pagePromise = getApiFactory()
        .purchaseOrder()
        .getGeneratedPurchaseOrderRevenue({
          startDate: startDate,
          endDate: endDate,
          purchaseOrderStates: purchaseOrderStates,
          branchId: branchId ?? emptyGuid,
          userId: userId ?? emptyGuid
        });
    }
    return await this._pagePromise;
  }
}

let _db: GeneratedPurchaseOrdersCache | null = null;
export function flushGeneratedPurchaseOrdersCache() {
  _db = null;
}
export function generatedPurchaseOrdersCache(): GeneratedPurchaseOrdersCache {
  if (!_db) {
    _db = new GeneratedPurchaseOrdersCache();
  }
  return _db;
}
