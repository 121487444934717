import { ChartSeriesXY } from '../data/chart-data-types';
import { customElement, property } from 'lit/decorators.js';
import { EventTemplate } from '../../../../webmodule-common/other/ui/events';
import { html, PropertyValueMap, TemplateResult } from 'lit';
import { ModalDialog } from '../../../../webmodule-common/other/ui/modal-base';
import { tlang } from '../../../../webmodule-common/other/language/lang';

const ApexCharts = (globalThis as any).ApexCharts;
@customElement('wm-timelinewidgetmodal')
export class TimelineWidgetModal extends ModalDialog {
  @property({ type: String })
  public eventTitle: string = '';
  @property({ type: Array })
  public seriesData: ChartSeriesXY[] = [];

  chart: any | null = null;

  protected getTitle(): string {
    return this.eventTitle;
  }
  protected async firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const options = {
      chart: {
        type: 'area',
        events: {
          updated: function (_chartContext, _config) {
            console.log(`ApexChart.updated called`);
            self.addFilterTextToLegend();
          }
        },
        height: 400,
        selection: {
          enabled: false
        },
        stacked: false,
        toolbar: {
          export: {
            csv: {
              headerCategory: 'Date'
            }
          },
          tools: {
            download: '<img class="apex-chartmanage-icon" src="assets/icons/apexCharts/menu.svg">',
            zoom: '<img class="apex-chartmanage-icon" src="assets/icons/apexCharts/zoom-range.svg">',
            zoomin: '<img class="apex-chartmanage-icon" src="assets/icons/apexCharts/zoom-in.svg">',
            zoomout: '<img class="apex-chartmanage-icon" src="assets/icons/apexCharts/zoom-out.svg">',
            pan: '<img class="apex-chartmanage-icon" src="assets/icons/apexCharts/move.svg">',
            reset: '<img class="apex-chartmanage-icon" src="assets/icons/apexCharts/fit-to-screen.svg">'
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: -10
      },
      series: this.seriesData,
      states: {
        hover: {
          filter: {
            type: 'none',
            value: 0
          }
        },
        active: {
          filter: {
            type: 'none',
            value: 0
          }
        }
      },
      stroke: {
        width: 1
      },
      xaxis: {
        type: 'datetime'
      },
      yaxis: {
        labels: {
          formatter: (value: number) => this.getYAxisLabel(value)
        }
      }
    };

    this.chart = new ApexCharts(this.ui.querySelector('.timeline-chart'), options);
    this.chart.render();
  }

  addFilterTextToLegend() {
    const element = document.querySelector('.timeline-chart > div > svg > foreignObject > div.apexcharts-legend');
    if (element && (element.firstElementChild as HTMLDivElement | null)?.innerText !== 'Filter: ') {
      const filterElement = document.createElement('div');
      filterElement.classList.add('filter-label');
      filterElement.classList.add('me-2');
      filterElement.innerText = 'Filter: ';
      element.prepend(filterElement);
    }
  }

  bodyTemplate(): EventTemplate {
    return html`<div class="timeline-chart"></div>`;
  }

  protected footerTemplate(): TemplateResult | null {
    return html` <webmodule-button size="small" variant="default" @click=${() => this.hideModal()}
      >${tlang`Close`}
    </webmodule-button>`;
  }

  protected get modalSize() {
    return 'modal-xl';
  }

  protected getYAxisLabel(value: number): string {
    return `$${Intl.NumberFormat('en-US', {
      notation: 'compact',
      maximumFractionDigits: 1
    }).format(value)}`;
  }
}
