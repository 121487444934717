// eslint-disable-next-line import/named
import { DataBinding } from '../databinding/databinding';
import { DataTracker } from '../databinding/data-tracker';
import { html, TemplateResult } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
import type { WebmoduleSelect, WebmoduleShowEvent } from '../../../components/src/webmodule-components';
import { WebmoduleOption } from '../../../components/src/webmodule-components';
import { when } from 'lit/directives/when.js';

export function updatePicker(select: WebmoduleSelect, value: string, display: string) {
  const childNodes = select.querySelectorAll('webmodule-option');
  childNodes.forEach(childNode => {
    childNode.remove();
  });

  const Opt = new WebmoduleOption();
  Opt.value = value;
  Opt.innerText = display;

  select.appendChild(Opt);
  select.value = value;
}

/*
export function formPicker(
  fieldName: string,
  title: string | undefined,
  display: string,
  dataBinding: DataBinding,
  dataTracker: DataTracker,
  clickEvent: () => void,
  required = false,
  readonly = false,
  classes = ''
): TemplateResult {
  const value = display && dataTracker.getObjectValue(fieldName) ? dataTracker.getObjectValue(fieldName) : undefined;

  const option =
    display && dataTracker.getObjectValue(fieldName)
      ? html` <webmodule-option value="${value}">${display}</webmodule-option>`
      : undefined;

  const selectShowEvent = (e: WebmoduleShowEvent) => {
    e.stopImmediatePropagation();
    e.preventDefault();
    clickEvent();
  };

  return html` <webmodule-select
    class="webmodule-control-left-label ${classes}"
    id=${dataBinding.field(fieldName)}
    ?required=${required}
    ?disabled="${readonly}"
    value="${ifDefined(value)}"
    @webmodule-show="${selectShowEvent}"
    size="small"
  >
    ${when(title, () => html` <span slot="label">${title}:</span> `)} ${when(option, () => option)}
  </webmodule-select>`;
}
*/

export function formPicker(
  fieldName: string,
  title: string | undefined,
  display: string,
  dataBinding: DataBinding,
  dataTracker: DataTracker,
  clickEvent: () => void,
  required = false,
  readonly = false,
  classes = ''
): TemplateResult {
  const value = display && dataTracker.getObjectValue(fieldName) ? dataTracker.getObjectValue(fieldName) : undefined;

  const option =
    display && dataTracker.getObjectValue(fieldName)
      ? html` <webmodule-option value="${value}">${display}</webmodule-option>`
      : undefined;

  const selectShowEvent = (e: WebmoduleShowEvent) => {
    e.stopImmediatePropagation();
    e.preventDefault();
    clickEvent();
  };

  return html` <webmodule-select
    class="webmodule-control-left-label ${classes}"
    id=${dataBinding.field(fieldName)}
    ?required=${required}
    ?disabled="${readonly}"
    .value="${ifDefined(value)}"
    @webmodule-show="${selectShowEvent}"
    size="small"
  >
    ${when(title, () => html` <span slot="label">${title}:</span> `)} ${when(option, () => option)}
  </webmodule-select>`;
}
