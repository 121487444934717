// noinspection HtmlUnknownAttribute
import { customElement, property, query, state } from 'lit/decorators.js';
// eslint-disable-next-line import/named
import { fileToBase64 } from '../../blob-converters';
import { getAssetCdnPath } from '../../common/assets';
import { html, LitElement } from 'lit';
import { information } from '../modal-option';
import { isEmptyOrSpace } from '../string-helper-functions';
import { tlang } from '../../language/lang';
import type { TemplateResult } from 'lit';

export class InputView extends LitElement {
  createRenderRoot() {
    return this;
  }

  render() {
    return html``;
  }

  attributeTrue(name: string) {
    if (!this.hasAttribute(name)) return false;
    else {
      const attr = this.getAttribute(name);
      return attr == undefined || attr !== 'false';
    }
  }
}

@customElement('bs-paginator')
export class Paginator extends InputView {
  index = 1;
  count = 1;

  static get properties() {
    return {
      index: { type: Number },
      count: { type: Number }
    };
  }

  render() {
    let currentPage = this.index;
    const pageCount = this.count;

    const visiblePages = 11;
    const pageDeductor = 5;
    let firstDisplayPage = currentPage - pageDeductor;
    if (firstDisplayPage < 1) firstDisplayPage = 1;
    let lastDisplayPage = firstDisplayPage + visiblePages - 1;
    if (lastDisplayPage > pageCount) lastDisplayPage = pageCount;

    if (lastDisplayPage === pageCount && lastDisplayPage - firstDisplayPage + 1 < visiblePages)
      firstDisplayPage = Math.max(1, lastDisplayPage - visiblePages + 1);
    if (firstDisplayPage === 1 && lastDisplayPage - firstDisplayPage + 1 < visiblePages)
      lastDisplayPage = Math.min(pageCount, firstDisplayPage + visiblePages - 1);

    const includeSkipToFirst = firstDisplayPage > 1;
    const includeSkipToLast = lastDisplayPage < pageCount - 1;

    const clickEvent = (e: Event) => {
      e.preventDefault();
      const strPage = (e.target as HTMLUListElement).textContent?.toLowerCase();
      if (strPage === 'prev') currentPage--;
      else if (strPage === 'next') currentPage++;
      else if (strPage === 'last') currentPage = pageCount;
      else if (strPage === 'first') currentPage = 1;
      else currentPage = parseInt(strPage ?? '1');

      //handle accidental overflows
      currentPage = Math.min(pageCount, Math.max(1, currentPage));
      const event = new CustomEvent('page-change', {
        detail: {
          index: currentPage - 1
        }
      });
      this.dispatchEvent(event);
    };

    const pages = (): TemplateResult[] => {
      const pageResult: TemplateResult[] = [];
      for (let i = firstDisplayPage; i <= lastDisplayPage; i++) {
        if (i === currentPage) {
          pageResult.push(
            html` <li class="page-item active">
              <span class="page-link">${i}</span>
            </li>`
          );
        } else {
          pageResult.push(
            html` <li class="page-item">
              <a class="page-link" href="#" @click=${clickEvent}>${i}</a>
            </li>`
          );
        }
      }
      return pageResult;
    };

    const skipFirstClass = `page-item ${!includeSkipToFirst ? 'disabled' : ''}`;
    const currentPageClass = `page-item ${currentPage === 1 ? 'disabled' : ''}`;
    const lastPageClass = `page-item ${currentPage === pageCount ? 'disabled' : ''}`;
    const skipToLastClass = `page-item ${!includeSkipToLast ? 'disabled' : ''}`;
    return html` <nav aria-label="Page navigation">
      <ul class="pagination pagination-sm justify-content-center">
        <li class=${skipFirstClass}>
          <a class="page-link" href="#" @click=${clickEvent}>First</a>
        </li>
        <li class=${currentPageClass}>
          <a class="page-link" href="#" rel="prev" @click=${clickEvent}>Prev</a>
        </li>
        ${pages()}
        <li class=${lastPageClass}>
          <a class="page-link" href="#" rel="prev" @click=${clickEvent}>Next</a>
        </li>
        <li class=${skipToLastClass}>
          <a class="page-link" href="#" @click=${clickEvent}>Last</a>
        </li>
      </ul>
    </nav>`;
  }
}

@customElement('bs-form-image-upload')
export class FormInputImageFileUploadView extends InputView {
  required?: boolean = false;
  @query('input')
  fileElement?: HTMLInputElement;
  @property({ type: Boolean }) readonly = false;
  @property() defaultImage = getAssetCdnPath('/assets/images/transparent-pixel.gif');

  @property({ type: Boolean }) showFileInput = true;
  @property({ type: Boolean }) isThumbnail = false;
  @property({ type: Boolean }) resize = false;
  @property({ type: Number }) resizeHeight = 400;
  @property({ type: Number }) resizeWidth = 400;
  @property({ type: Number }) displayType = 0;
  @property() clickCaption = 'upload image';
  @property({ type: Array }) acceptedFiles = ['image/gif', 'image/jpeg', 'image/png', 'image/svg+xml'];
  @property({ type: Boolean }) canDelete = false;
  private fileValue?: Blob;

  static get properties() {
    return {
      'data-label': { type: String },
      value: { type: String },
      required: { type: Boolean }
    };
  }

  private _fileExtension?: string;

  get fileExtension() {
    return this._fileExtension;
  }

  @state() private _value: string | undefined;

  public get value(): string | undefined {
    return this._value;
  }

  public set value(value: string | undefined) {
    this._value = value;
  }

  get isValueChanged(): boolean {
    if (this.fileElement?.files) return this.fileElement?.files.length > 0;

    return false;
  }

  get isImage() {
    return (
      (this.acceptedFiles.some(x => x.startsWith('image/')) && isEmptyOrSpace(this.value)) ||
      (!isEmptyOrSpace(this.value) && isImageLink(this.value))
    );
  }

  async ClearFiles() {
    if (this.fileElement?.files) {
      this.fileElement.files = null;
      this.fileElement.value = '';
    }
  }

  async base64Value() {
    if (this.fileValue) {
      return await fileToBase64(this.fileValue);
    }
    return '';
  }

  connectedCallback(): void {
    super.connectedCallback();
    this.addEventListener('dragover', this.eventDragOver);
    this.addEventListener('drop', this.eventDrop);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this.removeEventListener('dragover', this.eventDragOver);
    this.removeEventListener('drop', this.eventDrop);
  }

  render() {
    const reqSpan = this.required ? html`<span class="text-danger">*</span>` : html``;

    const changeEvent = e => {
      this.onPreview(e);
      const newEvent = new CustomEvent('webmodule-change', {
        detail: e,
        bubbles: true,
        composed: true
      });
      this.dispatchEvent(newEvent);
    };

    const deleteEvent = e => {
      e.stopImmediatePropagation();
      this.fileValue = undefined;
      this.value = undefined;
      const newEvent = new CustomEvent('webmodule-delete', {
        detail: e,
        bubbles: true,
        composed: true
      });
      this.dispatchEvent(newEvent);
    };

    const eventPickImage = (e: Event) => {
      e.stopImmediatePropagation();
      if (this.readonly) return;
      this.fileElement?.click();
    };
    const imgId = `${this.dataset.id}-img`;

    const showDelete = this.canDelete && !isEmptyOrSpace(this.value);

    const fileInputTemplate = html`<input
      id="${this.dataset.id}"
      class="form-control ${showDelete ? 'input-upload-delete' : ''}"
      type="file"
      accept=${this.acceptedFiles.join(',')}
      @change=${changeEvent}
      value=${this.value}
      ?disabled=${this.readonly}
      ?readonly=${this.readonly}
      ?hidden=${!this.showFileInput}
    />`;

    const deleteButtonTemplate = showDelete
      ? html`<webmodule-button class="image-upload-delete" size="small" variant="default" @click=${e => deleteEvent(e)}
          ><icon-delete></icon-delete>
        </webmodule-button>`
      : html``;

    //this is the default header as used in the branding where you can pick and image
    //or drag and drop an image
    const header = this.showFileInput
      ? html` <div class="row mb-3 align-items-center form-col-item image-upload-field">
          <label for=${this.dataset.id} class="col-2 form-col-label">${this.dataset.label}:${reqSpan}</label>
          <div class="col-10 form-col-input">${fileInputTemplate}${deleteButtonTemplate}</div>
        </div>`
      : fileInputTemplate; //this is a hidden input

    const imgSrc = isEmptyOrSpace(this.value) ? this.defaultImage : this.value;

    const isDefaultImg = isEmptyOrSpace(this.value);

    const imageTemplate = !this.isThumbnail
      ? //this is the original template as used on the branding page
        html`
          <div @click=${eventPickImage} class="row mb-3 align-items-center form-col-item image-upload-image">
            <div class="col-10 offset-0 offset-sm-2 offset-md-3 offset-xl-2 form-col-input">
              <div class="image-upload-wrapper">
                <img src=${imgSrc} id=${imgId} class="img-fluid " alt="Thumbnail for the uploaded file" />
              </div>
            </div>
          </div>
        `
      : //this is a new template for thumbnail
        html`
          <div class="single-file-drop-container" @click=${eventPickImage}>
            <img
              src=${imgSrc}
              id=${imgId}
              class="img-fluid ${isDefaultImg ? 'img-default-upload' : ''}"
              alt="Custom Request Thumbnail"
            />
            <h2>Thumbnail</h2>
            <div class="image-upload-icon">
              <i class="fa fa-solid fa-cloud-arrow-up"></i>
            </div>
            <div class="image-upload-button upload-button">${this.clickCaption}</div>
          </div>
        `;

    return html` ${header} ${imageTemplate} `;
  }

  resizeImage(file: File, maxWidth: number, maxHeight: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        const width = image.width;
        const height = image.height;

        if (width <= maxWidth && height <= maxHeight) {
          resolve(file);
          return;
        }

        let newWidth;
        let newHeight;

        if (width > height) {
          newHeight = height * (maxWidth / width);
          newWidth = maxWidth;
        } else {
          newWidth = width * (maxHeight / height);
          newHeight = maxHeight;
        }

        const canvas = document.createElement('canvas');
        canvas.width = newWidth;
        canvas.height = newHeight;

        const context = canvas.getContext('2d');
        if (!context) {
          resolve(file);
          return;
        }

        context.drawImage(image, 0, 0, newWidth, newHeight);

        canvas.toBlob(resolve as BlobCallback, file.type);
      };
      image.onerror = reject;
    });
  }

  protected eventDragOver = (e: Event) => {
    e.preventDefault();
  };

  protected eventDrop = async (e: DragEvent) => {
    e.preventDefault();
    if (this.readonly) return;

    if (this.fileElement && e.dataTransfer) {
      this.fileElement.files = e.dataTransfer.files;
      await this.onPreview(undefined);
    }
  };

  /**
   * Used to display a preview of a selected image file
   * @param event
   */
  private async onPreview(event?: Event) {
    const target = event?.target as HTMLInputElement;
    const file = target?.files?.item(0) ?? this.fileElement?.files?.item(0);
    if (file) {
      const validImageTypes = this.acceptedFiles;
      if (validImageTypes.includes(file.type)) {
        this._fileExtension = file.name.substring(file.name.lastIndexOf('.'));
        this.fileValue =
          this.resize && isImageLink(file.name)
            ? await this.resizeImage(file, this.resizeWidth, this.resizeHeight)
            : (file as Blob);
        this.value = window.URL.createObjectURL(this.fileValue);
      } else await information(tlang`Currently supported file types are ${this.acceptedFiles.join(', ')}`);
    }
  }
}

function isImageLink(value: string | undefined): boolean {
  if (!value) return false;
  const extension = value.substring(value.lastIndexOf('.')).toLowerCase();

  return extension === '.jpg' || extension === '.svg' || extension === '.png' || extension === '.gif';
}
