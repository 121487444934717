import { classMap } from 'lit/directives/class-map.js';
import { type CSSResultGroup, html, type PropertyValues } from 'lit';
import { defaultValue } from '../../common/default-value.js';
import { HasSlotController } from '../../common/slot-controller.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { live } from 'lit/directives/live.js';
import { localeCurrencyMap } from '../../common/helpers/localization-helpers.js';
import { LocalizeController } from '../../common/localize-controller.js';
import { monitor } from '../../common/monitor.js';
import { property, query, state } from 'lit/decorators.js';
import componentStyles from '../../styles/component.styles.js';
import formControlStyles from '../../styles/form-control.styles.js';
import styles from './input-money.styles.js';
import WebModuleElement from '../../common/webmodule-element.js';

/**
 * @summary Input with formatting for money values
 *
 * @slot label - The input's label. Alternatively, you can use the `label` attribute.
 * @slot prefix - Used to prepend a presentational icon or similar element to the input.
 * @slot suffix - Used to append a presentational icon or similar element to the input.
 * @slot clear-icon - An icon to use in lieu of the default clear icon.
 *
 * @event webmodule-blur - Emitted when the control loses focus.
 * @event webmodule-change - Emitted when an alteration to the control's value is committed by the user.
 * @event webmodule-clear - Emitted when the clear button is activated.
 * @event webmodule-focus - Emitted when the control gains focus.
 * @event webmodule-input - Emitted when the control receives input.
 * @event webmodule-keydown - Emitted when the key is pressed
 * @event webmodule-keyup - Emitted when the key is released*
 *
 * @csspart form-control - The form control that wraps the label, input, and help text.
 * @csspart form-control-label - The label's wrapper.
 * @csspart form-control-input - The input's wrapper.
 * @csspart base - The component's base wrapper.
 * @csspart input - The internal `<input>` control.
 * @csspart prefix - The container that wraps the prefix.
 * @csspart clear-button - The clear button.
 * @csspart suffix - The container that wraps the suffix.
 *
 * @tag webmodule-input-money
 */
export default class WebmoduleInputMoney extends WebModuleElement {
  static styles: CSSResultGroup = [componentStyles, formControlStyles, styles];
  protected static _sharedInput: null | HTMLInputElement = null;
  @query('.input__control') input: HTMLInputElement;
  @property() lang: string;
  /** Draws a pill-style input with rounded edges. */
  @property({ type: Boolean, reflect: true }) pill = false;
  /** Adds a clear button when the input is not empty. */
  @property({ type: Boolean }) clearable = false;
  /** Hides the browser's built-in increment/decrement spin buttons. */
  @property({ attribute: 'no-spin-buttons', type: Boolean }) noSpinButtons = false;
  /** The input's minimum value.*/
  @property() min: number | string;
  /** The input's maximum value.*/
  @property() max: number | string;
  @property() title = '';
  /** The name of the input, submitted as a name/value pair with form data. */
  @property() name = '';
  /** The current value of the input, submitted as a name/value pair with form data. */
  @property() value = '';
  /** The input's size. */
  @property({ reflect: true }) size: 'small' | 'medium' | 'large' = 'medium';
  /** Disables the input. */
  @property({ type: Boolean, reflect: true }) disabled = false;
  /** Makes the input a required field. */
  @property({ type: Boolean, reflect: true }) required = false;
  /** The input's label. If you need to display HTML, use the `label` slot instead. */
  @property() label = '';
  /** Makes the input readonly. */
  @property({ type: Boolean, reflect: true }) readonly = false;
  /** Draws a filled input. */
  @property({ type: Boolean, reflect: true }) filled = false;
  /** Placeholder text to show as a hint when the input is empty. */
  @property() placeholder = '';
  /** Indicates that the input should receive focus on page load. */
  @property({ type: Boolean }) autofocus: boolean;
  /** The default value of the form control. Primarily used for resetting the form control. */
  @defaultValue() defaultValue = '';
  /** Used to customize the label or icon of the Enter key on virtual keyboards. */
  @property() enterkeyhint: 'enter' | 'done' | 'go' | 'next' | 'previous' | 'search' | 'send';
  /** The [ISO 4217](https://en.wikipedia.org/wiki/ISO_4217) currency code to use. Default to USD (most of our clients */
  @property() currency?: string;
  /** The currency display format */
  @property({ attribute: 'currency-display' }) currencyDisplay: 'symbol' | 'narrowSymbol' | 'code' | 'name' =
    'narrowSymbol';
  private readonly hasSlotController = new HasSlotController(this, 'label');
  private readonly localizeController = new LocalizeController(this);
  @state() private hasFocus = false;
  @state() private displayValue = '';

  //
  // Using in-memory input getter/setter instead of the one in the template since the properties can be set before the component is rendered.
  //
  get valueAsNumber() {
    if (this.value && typeof this.value === 'string' && (this.value.endsWith('.') || this.value.endsWith(','))) {
      this.value += '0';
    }

    this._numberInput.value = this.value;
    return this.input?.valueAsNumber || this._numberInput.valueAsNumber;
  }

  set valueAsNumber(newValue: number) {
    this._numberInput.valueAsNumber = newValue;
    this.value = this._numberInput.value;
  }

  private get _numberInput() {
    const ctor = this.constructor as unknown as typeof WebmoduleInputMoney;
    if (ctor._sharedInput === null) ctor._sharedInput = document.createElement('input');

    const input = ctor._sharedInput;
    input.type = 'number';
    return input;
  }

  @monitor('value', { delayMonitorUntilFirstUpdate: true })
  async handleValueChange() {
    this.updateDisplayValue();
    await this.updateComplete;
  }

  firstUpdated() {
    this.updateDisplayValue();
  }

  focus(options?: FocusOptions) {
    this.input.focus(options);
  }

  blur() {
    this.input.blur();
  }

  select() {
    this.input.select();
  }

  setSelectionRange(
    selectionStart: number,
    selectionEnd: number,
    selectionDirection: 'forward' | 'backward' | 'none' = 'none'
  ) {
    this.input.setSelectionRange(selectionStart, selectionEnd, selectionDirection);
  }

  setRangeText(
    replacement: string,
    start?: number,
    end?: number,
    selectMode: 'select' | 'start' | 'end' | 'preserve' = 'preserve'
  ) {
    const selectionStart = start ?? this.input.selectionStart!;
    const selectionEnd = end ?? this.input.selectionEnd!;

    this.input.setRangeText(replacement, selectionStart, selectionEnd, selectMode);

    if (this.value !== this.input.value) {
      this.value = this.input.value;
    }
  }

  override updated(changedProperties: PropertyValues) {
    if (changedProperties.has('hasFocus') && this.hasFocus) {
      this.select();
    }
  }

  render() {
    const hasLabelSlot = this.hasSlotController.checkFor('label');
    const hasLabel = this.label ? true : !!hasLabelSlot;
    const hasClearIcon = this.clearable && !this.disabled && !this.readonly;
    const isClearIconVisible = hasClearIcon && (typeof this.value === 'number' || this.value.length > 0);

    return html`
      <div
        part="form-control"
        class=${classMap({
          'form-control': true,
          'form-control--small': this.size === 'small',
          'form-control--medium': this.size === 'medium',
          'form-control--large': this.size === 'large',
          'form-control--has-label': hasLabel
        })}
      >
        <label
          part="form-control-label"
          class="form-control__label"
          for="input"
          aria-hidden=${hasLabel ? 'false' : 'true'}
        >
          <slot name="label">${this.label}</slot>
        </label>

        <div part="form-control-input" class="form-control-input">
          <div
            part="base"
            class=${classMap({
              input: true,

              // Sizes
              'input--small': this.size === 'small',
              'input--medium': this.size === 'medium',
              'input--large': this.size === 'large',

              // States
              'input--pill': this.pill,
              'input--standard': !this.filled,
              'input--filled': this.filled,
              'input--disabled': this.disabled,
              'input--focused': this.hasFocus,
              'input--empty': !this.value,
              'input--no-spin-buttons': this.noSpinButtons,
              'input--negative-value': this.valueAsNumber < 0
            })}
          >
            <span part="prefix" class="input__prefix">
              <slot name="prefix"></slot>
            </span>

            <input
              part="input"
              id="input"
              class="input__control"
              type="text"
              title=${this.title}
              name=${ifDefined(this.name)}
              ?readonly=${this.readonly}
              placeholder=${ifDefined(this.placeholder)}
              .value=${live(this.displayValue)}
              ?autofocus=${this.autofocus}
              enterkeyhint=${ifDefined(this.enterkeyhint)}
              inputmode="decimal"
              @change=${this.handleChange}
              @input=${this.handleInput}
              @focus=${this.handleFocus}
              @blur=${this.handleBlur}
              @keydown=${this.handleKeyDown}
              @keyup=${this.handleKeyUp}
            />

            ${isClearIconVisible
              ? html`
                  <button
                    part="clear-button"
                    class="input__clear"
                    type="button"
                    aria-label="Clear input"
                    @click=${this.handleClearClick}
                    tabindex="-1"
                  >
                    <slot name="clear-icon">
                      <webmodule-icon name="x-circle-fill"></webmodule-icon>
                    </slot>
                  </button>
                `
              : ''}

            <span part="suffix" class="input__suffix">
              <slot name="suffix"></slot>
            </span>
          </div>
        </div>
      </div>
    `;
  }

  private handleBlur() {
    this.hasFocus = false;
    this.updateDisplayValue();
    this.emit('webmodule-blur');
  }

  private handleChange() {
    const value = this._numberInput.valueAsNumber;
    if (isNaN(value)) this.value = '';
    else this.value = value.toFixed(2);
    this.emit('webmodule-change');
  }

  private handleClearClick(event: MouseEvent) {
    event.preventDefault();

    if (this.value !== '') {
      this.value = '';
      this.emit('webmodule-clear');
      this.emit('webmodule-input');
      this.emit('webmodule-change');
    }

    this.input.focus();
  }

  private handleFocus() {
    this.hasFocus = true;
    this.updateDisplayValue();
    this.emit('webmodule-focus');
  }

  private handleInput() {
    let value = this.input.value;

    // Allow only numeric values and a single decimal point
    value = value.replace(/[^0-9.e-]/g, '');

    this.value = value;
    this.input.value = value;

    this.emit('webmodule-input');
  }

  private handleKeyDown(event: KeyboardEvent) {
    this.emit('webmodule-keydown', { detail: event });
  }

  private handleKeyUp(event: KeyboardEvent) {
    this.emit('webmodule-keyup', { detail: event });
  }

  private updateDisplayValue() {
    if (this.value === '' || this.hasFocus) {
      this.displayValue = this.value;
      return;
    }

    let displayVal = this.localizeController.number(Math.abs(this.valueAsNumber), {
      style: 'currency',
      currency: localeCurrencyMap[this.localizeController.lang()] || this.currency || 'USD',
      currencyDisplay: this.currencyDisplay
    });

    if (this.valueAsNumber < 0) displayVal = `(${displayVal})`;

    this.displayValue = displayVal;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'webmodule-input-money': WebmoduleInputMoney;
  }
}
