import { classMap } from 'lit/directives/class-map.js';
import { HasSlotController } from '../../common/slot-controller.js';
import { html } from 'lit';
import { monitor } from '../../common/monitor.js';
import { property, query, state } from 'lit/decorators.js';
import componentStyles from '../../styles/component.styles.js';
import formControlStyles from '../../styles/form-control.styles.js';
import styles from './radio-group.styles.js';
import WebModuleElement from '../../common/webmodule-element.js';
import type { CSSResultGroup } from 'lit';
import type WebmoduleRadio from '../radio/radio.js';
import type WebmoduleRadioButton from '../radio-button/radio-button.js';

/**
 * @summary Radio groups are used to group multiple radios or radio buttons.
 *
 * @slot - The default slot where `<webmodule-radio>` or `<webmodule-radio-button>` elements are placed.
 * @slot label - The radio group's label. Required for proper accessibility. Alternatively, you can use the `label`
 *  attribute.
 *
 * @event webmodule-change - Emitted when the radio group's selected value changes.
 * @event webmodule-input - Emitted when the radio group receives user input.
 *
 * @csspart form-control - The form control that wraps the label, input, and help text.
 * @csspart form-control-label - The label's wrapper.
 * @csspart form-control-input - The input's wrapper.
 * @csspart button-group - The button group that wraps radio buttons.
 * @csspart button-group__base - The button group's `base` part.
 *
 * @tag webmodule-radio-group
 */
export default class WebmoduleRadioGroup extends WebModuleElement {
  static styles: CSSResultGroup = [componentStyles, formControlStyles, styles];
  @query('slot:not([name])') defaultSlot: HTMLSlotElement;
  @state() defaultValue = '';
  /**
   * The radio group's label. If you need to display HTML, use the `label` slot instead.
   */
  @property() label = '';
  /** The name of the radio group, submitted as a name/value pair with form data. */
  @property() name = 'option';
  /** The current value of the radio group, submitted as a name/value pair with form data. */
  @property({ reflect: true }) value = '';
  /** The radio group's size. This size will be applied to all child radios and radio buttons. */
  @property({ reflect: true }) size: 'small' | 'medium' | 'large' = 'medium';
  /** Ensures a child radio is checked before allowing the containing form to submit. */
  @property({ type: Boolean, reflect: true }) required = false;
  /** Set to render all child radios inline. Only applies to radios*/
  @property({ type: Boolean, reflect: true }) inline = false;

  private readonly hasSlotController = new HasSlotController(this, 'label');
  @state() private hasButtonGroup = false;

  connectedCallback() {
    super.connectedCallback();
    this.defaultValue = this.value;
  }

  @monitor('size', { delayMonitorUntilFirstUpdate: true })
  handleSizeChange() {
    this.syncRadios();
  }

  @monitor('value')
  handleValueChange() {
    if (this.hasUpdated) {
      this.updateCheckedRadio();
    }
  }

  render() {
    const hasLabelSlot = this.hasSlotController.checkFor('label');
    const hasLabel = this.label ? true : !!hasLabelSlot;
    const defaultSlot = html`
      <slot @slotchange=${this.syncRadios} @click=${this.handleRadioClick} @keydown=${this.handleKeyDown}></slot>
    `;

    return html`
      <fieldset
        part="form-control"
        class=${classMap({
          'form-control': true,
          'form-control--small': this.size === 'small',
          'form-control--medium': this.size === 'medium',
          'form-control--large': this.size === 'large',
          'form-control--radio-group': true,
          'form-control--inline-radio': this.inline,
          'form-control--has-label': hasLabel
        })}
        role="radiogroup"
        aria-labelledby="label"
        aria-errormessage="error-message"
        v
      >
        <label
          part="form-control-label"
          id="label"
          class="form-control__label"
          aria-hidden=${hasLabel ? 'false' : 'true'}
          @click=${this.handleLabelClick}
        >
          <slot name="label">${this.label}</slot>
        </label>

        <div part="form-control-input" class="form-control-input">
          ${this.hasButtonGroup
            ? html`
                <webmodule-button-group part="button-group" exportparts="base:button-group__base" role="presentation">
                  ${defaultSlot}
                </webmodule-button-group>
              `
            : defaultSlot}
        </div>
      </fieldset>
    `;
  }

  private getAllRadios() {
    return [...this.querySelectorAll<WebmoduleRadio | WebmoduleRadioButton>('webmodule-radio, webmodule-radio-button')];
  }

  private handleRadioClick(event: MouseEvent) {
    const target = (event.target as HTMLElement).closest<WebmoduleRadio | WebmoduleRadioButton>(
      'webmodule-radio, webmodule-radio-button'
    )!;
    const radios = this.getAllRadios();
    const oldValue = this.value;

    if (!target || target.disabled) {
      return;
    }

    this.value = target.value;
    radios.forEach(radio => (radio.checked = radio === target));

    if (this.value !== oldValue) {
      this.emit('webmodule-change');
      this.emit('webmodule-input');
    }
  }

  private handleKeyDown(event: KeyboardEvent) {
    if (!['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', ' '].includes(event.key)) {
      return;
    }

    const radios = this.getAllRadios().filter(radio => !radio.disabled);
    const checkedRadio = radios.find(radio => radio.checked) ?? radios[0];
    const incr = event.key === ' ' ? 0 : ['ArrowUp', 'ArrowLeft'].includes(event.key) ? -1 : 1;
    const oldValue = this.value;
    let index = radios.indexOf(checkedRadio) + incr;

    if (index < 0) {
      index = radios.length - 1;
    }

    if (index > radios.length - 1) {
      index = 0;
    }

    this.getAllRadios().forEach(radio => {
      radio.checked = false;

      if (!this.hasButtonGroup) {
        radio.setAttribute('tabindex', '-1');
      }
    });

    this.value = radios[index].value;
    radios[index].checked = true;

    if (!this.hasButtonGroup) {
      radios[index].setAttribute('tabindex', '0');
      radios[index].focus();
    } else {
      radios[index].shadowRoot!.querySelector('button')!.focus();
    }

    if (this.value !== oldValue) {
      this.emit('webmodule-change');
      this.emit('webmodule-input');
    }

    event.preventDefault();
  }

  private handleLabelClick() {
    const radios = this.getAllRadios();
    const checked = radios.find(radio => radio.checked);
    const radioToFocus = checked || radios[0];

    if (radioToFocus) {
      radioToFocus.focus();
    }
  }

  private async syncRadioElements() {
    const radios = this.getAllRadios();

    await Promise.all(
      // Sync the checked state and size
      radios.map(async radio => {
        await radio.updateComplete;
        radio.checked = radio.value === this.value;
        radio.size = this.size;
      })
    );

    this.hasButtonGroup = radios.some(radio => radio.tagName.toLowerCase() === 'webmodule-radio-button');

    if (radios.length > 0 && !radios.some(radio => radio.checked)) {
      if (this.hasButtonGroup) {
        const buttonRadio = radios[0].shadowRoot?.querySelector('button');

        if (buttonRadio) {
          buttonRadio.setAttribute('tabindex', '0');
        }
      } else {
        radios[0].setAttribute('tabindex', '0');
      }
    }

    if (this.hasButtonGroup) {
      const buttonGroup = this.shadowRoot?.querySelector('webmodule-button-group');

      if (buttonGroup) {
        buttonGroup.disableRole = true;
      }
    }
  }

  private syncRadios() {
    if (customElements.get('webmodule-radio') && customElements.get('webmodule-radio-button')) {
      this.syncRadioElements();
      return;
    }

    if (customElements.get('webmodule-radio')) {
      this.syncRadioElements();
    } else {
      customElements.whenDefined('webmodule-radio').then(() => this.syncRadios());
    }

    if (customElements.get('webmodule-radio-button')) {
      this.syncRadioElements();
    } else {
      customElements.whenDefined('webmodule-radio-button').then(() => this.syncRadios());
    }
  }

  private updateCheckedRadio() {
    const radios = this.getAllRadios();
    radios.forEach(radio => (radio.checked = radio.value === this.value));
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'webmodule-radio-group': WebmoduleRadioGroup;
  }
}
