import {
  AddConversationAttachment,
  InputUpdateConversationEntry,
  ViewConversationAttachment,
  ViewConversationEntry
} from '../../api/dealer-api-interface-franchisee';
import { asMarkdownTemplate } from '../../../webmodule-common/other/general/markdown';
import { autoElement } from '../../../webmodule-common/components/src/common/lit-decorator-extended';
import { buildModifiedTime } from './conversation-helper';
import { cache } from '../cache-impl/cache-registry';
import { css, html, PropertyValueMap, TemplateResult } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { DateTime } from 'luxon';
import { Editor } from '@toast-ui/editor';
import { emptyGuid, newGuid } from '../../../webmodule-common/other/api/guid';
import { getApiFactory } from '../../api/api-injector';
import { getCurrentUser } from '../../../webmodule-common/other/api/current-user';
import { getInitials, isEmptyOrSpace } from '../../../webmodule-common/other/ui/string-helper-functions';
import { information } from '../../../webmodule-common/other/ui/modal-option';
import { lapsedMinutes, sleep } from '../../../webmodule-common/other/general/time';
import { LitElementBase } from '../../../webmodule-common/other/litelement-base';
import { lockUIandExecute } from '../../../webmodule-common/other/ui-lock';
import { newStandardEditor } from '../../../webmodule-common/other/ui/toastui';
import { previewImage } from '../../../webmodule-common/other/ui/modal-img';
import { repeat } from 'lit/directives/repeat.js';
import {
  serverDateTimeToLocal,
  serverDateTimeToLocalDateTime
} from '../../../webmodule-common/other/datetime-converter';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { WebModuleConversationEntryDropZone } from './wm-conversation-entry-dropzone';
import { when } from 'lit-html/directives/when.js';
import WebModuleElement from '../../../webmodule-common/components/src/common/webmodule-element';

@customElement('wmview-conversation-users')
export class WebModuleConversationUsersView extends LitElementBase {
  async updateUserNames() {
    const names = await cache().userProfile.getMany(this.users);
    this.userNames = names?.map(x => `${x.displayValue}`) ?? [];
  }

  @property()
  private _users: string[] = [];
  public get users(): string[] {
    return this._users;
  }
  public set users(value: string[]) {
    this._users = value;
    this.updateUserNames();
  }
  @state() userNames: string[] = [];

  render() {
    const userNameTemplates = this.userNames.map(x => this.userNameTemplate(x));
    if (this.userNames.length === 0) return html``;
    return html`<div class="conversation-names"><label>${tlang`Attention:`}</label>${userNameTemplates}</div>`;
  }
  userNameTemplate(userDisplayName: string): any {
    return html` <webmodule-tag pill variant="neutral" size="small">${userDisplayName}</webmodule-tag>`;
  }
}

export interface ConversationEntryUpdateEvent {
  editor: WMConversationEntryEditorBase;
  input: InputUpdateConversationEntry;
}
class WMConversationEntryEditorBase extends LitElementBase {
  @property() forceUpdate = false;
  protected tenantId = getCurrentUser()?.tenantId;
  @state()
  protected _isUploading = false;
  protected get isUploading() {
    return this._isUploading;
  }
  protected set isUploading(value) {
    if (value !== this._isUploading) {
      this._isUploading = value;
    }
  }
  @state()
  lapsedMinutes = 0;

  @state()
  protected _editing = false;

  protected get editing() {
    return this._editing;
  }
  protected set editing(value) {
    if (value != this._editing) {
      this._editing = value;
      if (value) this.createEditor();
      else {
        this.editor = undefined;
        this.textEditor?.remove();
        this.textEditor = undefined;
      }
    }
  }
  @property() attachmentsOnly = false;
  protected isUserSameTenant(id: string) {
    return cache().userProfile.getLocalData(id)?.tenantId === this.tenantId;
  }

  textEditor?: HTMLElement;
  protected editor?: Editor;
  @state() lastModified? = '';
  @property()
  private _conversationEntry?: ViewConversationEntry | undefined;
  public get conversationEntry(): ViewConversationEntry | undefined {
    return this._conversationEntry;
  }
  public set conversationEntry(value: ViewConversationEntry | undefined) {
    //LIT Tracks state by reference, not content.
    //when we reassign the same object back, it doesn't automatically trigger repaints
    //by adding the lastmodified as a stateful, we ensure proper refreshing of content
    this.lastModified = value?.lastModified;

    this._conversationEntry = value;
    if (this._conversationEntry) {
      this.lapsedMinutes = lapsedMinutes(serverDateTimeToLocal(this._conversationEntry.dateCreated));

      this.updateUserName();
      this.dateCreated = serverDateTimeToLocalDateTime(this._conversationEntry.dateCreated).toLocaleString(
        DateTime.DATETIME_FULL
      );
      const modifedDate = serverDateTimeToLocalDateTime(this._conversationEntry.dateCreated);
      this.modifiedTime = buildModifiedTime(modifedDate);
    } else {
      this.userName = '';
      this.lapsedMinutes = 0;
    }
    this.setStates();
    this.editor?.setMarkdown(this.conversationEntry?.text ?? '');
    this.attachmentsEditor?.resetAttachments(this.conversationEntry?.attachments ?? []);
  }
  @query('#attachments')
  attachmentsEditor?: WebModuleConversationEntryDropZone;

  @state() protected userName = '';
  @state() protected dateCreated = '';
  @state() protected modifiedTime = '';
  @property() maxFiles = 20;
  @property() allowCancel = true;
  async updateUserName() {
    if (this.conversationEntry)
      this.userName = (await cache().userProfile.get(this.conversationEntry?.systemUserId))?.displayValue ?? '';
  }
  eventClickChild = async (e: Event) => {
    if (e.target instanceof HTMLImageElement) {
      e.preventDefault();
      e.stopImmediatePropagation();
      await previewImage(e.target.src);
    }
  };
  get isAuthorSupplier(): boolean {
    return this.conversationEntry !== undefined && !this.isUserSameTenant(this.conversationEntry.systemUserId);
  }
  connectedCallback(): void {
    super.connectedCallback();
    this.addEventListener('click', this.eventClickChild);
  }
  disconnectedCallback(): void {
    super.disconnectedCallback();
    this.removeEventListener('click', this.eventClickChild);
  }

  createEditor() {
    if (!this.textEditor && !this.editor) {
      this.textEditor = document.createElement('div');
      this.editor = newStandardEditor(this.textEditor, undefined, 145);
      this.editor.setMarkdown(this.conversationEntry?.text ?? '');
      this.attachmentsEditor?.resetAttachments(this.conversationEntry?.attachments ?? []);
    }
  }
  protected firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    if (this.editing) this.createEditor();
  }
  get conversationEntryClasses() {
    return this.isAuthorSupplier ? 'conversation-entry author-supplier' : 'conversation-entry author-dealer';
  }
  get conversationIconTemplate() {
    return !this.isAuthorSupplier
      ? html`<i class="fa-regular fa-comment conversation-icon"></i>`
      : html`<i class="fa-solid fa-comments conversation-icon conversation-supplier-icon"></i>`;
  }
  protected eventAttachmentsChanged = (
    e: CustomEvent<{
      isUploading: boolean;
      files: ViewConversationAttachment[];
    }>
  ) => {
    this.isUploading = e.detail.isUploading;
  };
  get isOwner() {
    return getCurrentUser()?.id == this.conversationEntry?.systemUserId;
  }

  @state() isDraft = false;
  @state() isEditable = false;

  setStates() {
    this.isDraft = this.isOwner && (this.conversationEntry?.draft ?? false);
    if (!this.conversationEntry) return;
    const expired = this.lapsedMinutes > 25;
    if (!expired) console.log(`lapsed time on ${this.lapsedMinutes}`);
    this.isEditable = !this.isReadonly && this.isOwner && (!expired || this.isDraft);
  }

  get isReadonly() {
    return this.readonly || this.attributeTrue('readonly');
  }

  protected async updateData(data: InputUpdateConversationEntry) {
    await lockUIandExecute(async () => {
      const result = await getApiFactory().franchisee().updateConversationEntry(data);

      if (result && this.conversationEntry) {
        Object.assign(this.conversationEntry, result.conversationEntry);
        this.editing = false;
      }
    });
    await sleep(5000);
  }
  protected get embeddedAttachmentsAsInputs() {
    return (
      this.conversationEntry?.attachments
        .filter(x => x.embedded)
        .map(x => {
          return { filename: x.filename };
        }) ?? []
    );
  }
  protected get embeddedAttachments() {
    return this.conversationEntry?.attachments.filter(x => x.embedded) ?? [];
  }
  public get allAttachmentsAsInputs() {
    return [...this.embeddedAttachmentsAsInputs, ...(this.attachmentsEditor?.allFilesAsInputs ?? [])];
  }
  public get allAttachments() {
    return [...this.embeddedAttachments, ...(this.attachmentsEditor?.existingFiles ?? [])];
  }

  protected updateObject(): ConversationEntryUpdateEvent {
    const allAttachments = this.allAttachmentsAsInputs;
    const data: InputUpdateConversationEntry = {
      forceUpdate: this.forceUpdate,
      conversationEntryId: this.conversationEntry?.id ?? emptyGuid,
      conversationId: this.conversationEntry?.conversationId ?? emptyGuid,
      deleteEntry: false,
      draft: this.conversationEntry?.draft ?? false,
      attachments: allAttachments,
      users: this.conversationEntry?.users ?? [],
      text: this.editor?.getMarkdown() ?? '',
    };
    return { input: data, editor: this };
  }
  protected eventSaveDraft = async (e: Event) => {
    e.stopPropagation();
    if (!this.conversationEntry) return;
    const data = this.updateObject();
    data.input.draft = true;
    data.input.deleteEntry = false;
    await this.updateData(data.input);
    this.dispatchCustom('update', data);
  };
  protected eventDelete = async (e: Event) => {
    e.stopPropagation();
    if (!this.conversationEntry) return;
    const data = this.updateObject();

    data.input.deleteEntry = true;
    await this.updateData(data.input);
    this.dispatchCustom('delete', data);
  };
  public async post(silently?: boolean) {
    await this.internalPost(silently);
  }
  protected async internalPost(silently?: boolean) {
    if (!this.conversationEntry) return;
    const data = this.updateObject();
    data.input.deleteEntry = false;
    data.input.draft = false;
    await this.updateData(data.input);
    if (!silently) this.dispatchCustom('post', data);
  }
  protected eventPost = async (e: Event) => {
    e.stopPropagation();
    await this.internalPost();
  };
  protected eventCancelEdit = async (e: Event) => {
    e.stopPropagation();
    if (!this.conversationEntry) return;
    this.editing = false;
    this.dispatchCustom('cancel', { editor: this });
  };
  editingFooter() {
    const buttons: TemplateResult[] = [];

    if (this.isDraft) {
      buttons.push(
        html` <webmodule-button size="small" variant="default" @click=${this.eventDelete} class="ms-1">
          <webmodule-icon name="fas-trash-can" library="fa" slot="prefix"></webmodule-icon>
        </webmodule-button>`
      );
    }
    if (this.allowCancel)
      buttons.push(
        html`<webmodule-button
          size="small"
          variant="default"
          ?disabled=${this.isUploading}
          @click=${this.eventCancelEdit}
          class="ms-1"
        >
          ${tlang`Cancel`}
        </webmodule-button>`
      );
    if (this.isDraft) {
      buttons.push(
        html`<webmodule-button
          size="small"
          variant="default"
          ?disabled=${this.isUploading}
          @click=${this.eventSaveDraft}
          class="ms-1"
        >
          ${tlang`Save Draft Comment`}
        </webmodule-button>`
      );
    }
    buttons.push(
      html`<webmodule-button
        size="small"
        variant="primary"
        ?disabled=${this.isUploading}
        @click=${this.eventPost}
        class="ms-1"
      >
        ${tlang`Post Comment`}
      </webmodule-button>`
    );

    return html`<div class="conversation-list-actions">${buttons}</div>`;
  }

  renderHeader() {
    return html`<div>
      <span class="user-name fw-bold">${this.userName}</span>

      <span class="date-created">${this.dateCreated}</span>

      <span class="last-edit">${tlang` posted `} ${this.modifiedTime}</span>
    </div>`;
  }

  renderEdit() {
    return this.textEditor;
  }

  renderView() {
    return isEmptyOrSpace(this.conversationEntry?.text)
      ? html`<span class="text-muted">${tlang`There is no content in this entry`}</span>`
      : asMarkdownTemplate(this.conversationEntry?.text ?? '');
  }

  defaultHeaderTemplate() {
    return this.attachmentsOnly
      ? html`<div class="conversation-entry-header fw-bold">${this.renderHeader()}</div>`
      : html`<div class="conversation-entry-header fw-bold">
          ${this.renderHeader()}
          <wmview-conversation-users .users=${this.conversationEntry?.users}></wmview-conversation-users>
        </div>`;
  }
  protected getHeaderTemplate(): TemplateResult {
    return this.defaultHeaderTemplate();
  }
  protected attachmentsTemplate(): TemplateResult {
    return html``;
  }
  protected footerTemplate(): TemplateResult {
    return html``;
  }
  render() {
    const conversationIcon = this.conversationIconTemplate;
    const content = this.editing ? this.renderEdit() : this.renderView();
    const headerTemplate = this.getHeaderTemplate();
    const classes = this.conversationEntryClasses;

    return this.attachmentsOnly
      ? html`<div class=${classes}>${conversationIcon} ${headerTemplate} ${this.attachmentsTemplate()}</div>`
      : html`
          <div class=${classes}>
            ${conversationIcon} ${headerTemplate}
            <div class="conversation-text">${content}</div>
            ${this.attachmentsTemplate()} ${this.footerTemplate()}
          </div>
        `;
  }
  protected readonlyAttachmentsTemplate() {
    const attachmentTemplate = (item: ViewConversationAttachment) => {
      return html`
        <div class="conversation-attachment d-inline me-3">
          <i class="fa-regular fa-file-lines me-1"></i><a target="_blank" href=${item.publicUrl}>${item.filename}</a>
        </div>
      `;
    };
    const attachmentTemplate1 = (item: ViewConversationAttachment) => {
      return html`
        <div class="col-12 conversation-attachment">
          <i class="fa-regular fa-file-lines me-1"></i><a target="_blank" href=${item.publicUrl}>${item.filename}</a>
        </div>
      `;
    };

    if (!this.conversationEntry) return html``;
    const attachments = this.conversationEntry.attachments.filter(x => !x.embedded);
    //not editing

    if (attachments.length === 0) return html``;

    return this.attachmentsOnly
      ? html`<div class="conversation-attachments-section mt-2">
          ${repeat(
            attachments,
            item => item.virtualPath,
            item => attachmentTemplate1(item)
          )}
        </div>`
      : html`<div class="conversation-attachments-section mt-2">
          <div>${tlang`Attachments`}</div>
          ${repeat(
            attachments,
            item => item.virtualPath,
            item => attachmentTemplate(item)
          )}
        </div>`;
  }
}

@customElement('wmview-conversation-entry-view')
export class WebModuleConversationEntryView extends WMConversationEntryEditorBase {
  @property() inlineEditing = true;

  protected eventEdit = () => {
    if (this.inlineEditing) {
      this.editing = true;
      this.editor?.setMarkdown(this.conversationEntry?.text ?? '');
    } else {
      this.dispatchCustom('request-edit', {
        editor: this,
        entry: this.conversationEntry
      });
    }
  };

  protected attachmentsTemplate(): TemplateResult {
    if (this.isReadonly || !this.editing) return this.readonlyAttachmentsTemplate();

    if (!this.conversationEntry) return html``;
    const attachments = this.conversationEntry.attachments.filter(x => !x.embedded);

    return html`<wmview-conversation-attachments
      id="attachments"
      .maxFiles=${this.maxFiles}
      @wm-event-changed=${this.eventAttachmentsChanged}
      .conversationId=${this.conversationEntry.conversationId}
      .conversationEntryId=${this.conversationEntry.id}
      .existingFiles=${attachments}
    ></wmview-conversation-attachments> `;
  }
  get showFooter() {
    return !this.isReadonly && (this.isDraft || this.isEditable);
  }

  staticFooter() {
    const buttons: TemplateResult[] = [];
    if (!this.isReadonly) {
      if (this.isEditable) {
        buttons.push(
          html`<div @click=${this.eventEdit} class="ms-2 conversation-item-actions-action">
            <i class="fa-solid fa-pen-to-square"></i>
          </div>`
        );
      }
      if (this.isDraft)
        buttons.push(
          html`<div @click=${this.eventDelete} class="ms-2 conversation-item-actions-action">
            <i class="fa-solid fa-trash-can"></i>
          </div>`
        );
    }
    return html`<div class="conversation-item-actions">${buttons}</div>`;
  }

  footerTemplate() {
    if (!this.showFooter) return html``;
    return this.editing ? this.editingFooter() : this.staticFooter();
  }
}

@customElement('wmview-conversation-entry-editor')
export class WebModuleConversationEntryEditorView extends WMConversationEntryEditorBase {
  @property() autoSaveWhenAttachmentsOnly = true;
  @property() allowDownloadLinks = false;

  protected override get editing() {
    return true;
  }
  protected override set editing(_value) {
    this._editing = true;
  }

  get conversationIconTemplate() {
    if (!this.showHeader) return html``;
    return super.conversationIconTemplate;
  }
  public set isUploading(value) {
    if (value !== this._isUploading) {
      this._isUploading = value;

      if (!value && this.attachmentsOnly && this.autoSaveWhenAttachmentsOnly) {
        this.internalPost();
      }
    }
  }

  @property() attachmentsCaption = tlang`Attachments`;
  @property() showHeader = true;

  protected getHeaderTemplate() {
    return !this.showHeader ? html`` : this.defaultHeaderTemplate();
  }

  protected eventAttachmentsChanged = (
    e: CustomEvent<{
      isUploading: boolean;
      files: ViewConversationAttachment[];
    }>
  ) => {
    this.dispatchCustom('attachment-uploaded', {
      attachments: this.allAttachmentsAsInputs,
      conversationAttachments: this.allAttachments
    });
    this.isUploading = e.detail.isUploading;
  };
  protected attachmentsTemplate(): TemplateResult {
    //   if (this.isReadonly) return this.readonlyAttachmentsTemplate();
    if (!this.conversationEntry) return html``;
    const attachments = this.conversationEntry.attachments.filter(x => !x.embedded);

    return html`<wmview-conversation-attachments
      id="attachments"
      .maxFiles=${this.maxFiles}
      .readonly=${this.isReadonly}
      .allowDownloadLinks=${this.allowDownloadLinks}
      .caption=${this.attachmentsCaption}
      @wm-event-changed=${this.eventAttachmentsChanged}
      .conversationId=${this.conversationEntry.conversationId}
      .conversationEntryId=${this.conversationEntry.id}
      .existingFiles=${attachments}
    ></wmview-conversation-attachments> `;
  }
  get showFooter() {
    return true;
  }

  footerTemplate() {
    if (!this.showFooter) return html``;
    return this.editingFooter();
  }
}

export interface CreateConversationEntry {
  editor: WebModuleConversationEntryCreate;
  id: string;
  draft: boolean;
  text: string;
  attachments: AddConversationAttachment[];
  users: string[];
}

interface NotifyUser {
  initials: string;
  email: string;
}

@autoElement()
export class WebModuleConversationEntryCreate extends LitElementBase {
  @state() existingFiles: ViewConversationAttachment[] = [];
  @property() allowDraft = true;
  @property() allowCancel = true;

  @property()
  public get text() {
    return this.editor?.getMarkdown();
  }
  @property() conversationId = '';
  @property()
  private _conversationEntryId = newGuid();

  @property() postCaption = tlang`Post Comment`;

  public get conversationEntryId() {
    return this._conversationEntryId;
  }
  public set conversationEntryId(value) {
    if (value !== this._conversationEntryId) {
      this._conversationEntryId = value;
      this.editor?.setMarkdown('');
      this.existingFiles = [];
    }
  }
  @state() isUploading = false;
  @query('#textEditor') textEditor?: HTMLElement;
  protected editor?: Editor;

  @query('#attachments')
  attachmentsEditor?: WebModuleConversationEntryDropZone;

  @property({ type: Object, attribute: false })
  users: string[] = [];

  protected firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    if (this.textEditor && !this.editor) {
      this.editor = newStandardEditor(this.textEditor, undefined, 145);
    }
  }

  render() {
    return html`
      <div class="conversation-entry-create">
        <div class="conversation-entry-header"></div>
        <div id="textEditor"></div>
        
        ${this.userListTemplate()}
        ${this.attachmentsTemplate()}
        ${this.footer()}
      </div>
    `;
  }

  handleAddUser() {
    //Render add user modal
  }

  protected eventUploadingChanged = (e: CustomEvent<{ isUploading: boolean }>) => {
    this.isUploading = e.detail.isUploading;
  };

  userListTemplate(): TemplateResult {
    return html``;
    // return html`
    // <wm-webmoduleconversationuserlist
    //   .users=${this.users}
    //   @wm-add-user-requested="${this.handleAddUser}">
    // </wm-webmoduleconversationuserlist>
    // `;
  }

  attachmentsTemplate(): unknown {
    return html` <wmview-conversation-attachments
      id="attachments"
      @wm-event-changed=${this.eventUploadingChanged}
      .conversationId=${this.conversationId}
      .conversationEntryId=${this.conversationEntryId}
      .existingFiles=${this.existingFiles}
    ></wmview-conversation-attachments>`;
  }
  
  footer() {
    const saveData = async (draft: boolean) => {
      const data: CreateConversationEntry = {
        editor: this,
        draft: draft,
        id: this.conversationEntryId,
        attachments: this.attachmentsEditor?.allFilesAsInputs ?? [],
        users: this.users,
        text: this.editor?.getMarkdown() ?? ''
      };
      if (isEmptyOrSpace(data.text)) {
        await information(tlang`Please add content to the comment before posting`);
        return;
      }
      this.existingFiles = [];
      this.dispatchCustom('create', data);
    };

    const eventClick = async (e: Event) => {
      e.stopPropagation();
      await saveData(false);
    };

    const eventSaveDraft = async (e: Event) => {
      e.stopPropagation();
      await saveData(true);
    };
    const eventCancel = (e: Event) => {
      e.stopPropagation();
      this.dispatchCustom('cancel', { editor: this });
    };
    const button: TemplateResult[] = [];
    if (this.allowCancel) {
      button.push(
        html`<webmodule-button size="small" variant="default" ?disabled=${this.isUploading} @click=${eventCancel}>
          ${tlang`Cancel`}
        </webmodule-button>`
      );
    }
    if (this.allowDraft) {
      button.push(
        html`<webmodule-button size="small" variant="default" ?disabled=${this.isUploading} @click=${eventSaveDraft}>
          ${tlang`Save Draft Comment`}
        </webmodule-button>`
      );
    }
    button.push(
      html` <webmodule-button size="small" variant="primary" ?disabled=${this.isUploading} @click=${eventClick}>
        ${this.postCaption}
      </webmodule-button>`
    );

    return html`<div class="conversation-list-actions">${button}</div>`;
  }
}

@autoElement()
export class WebModuleConversationUserList extends WebModuleElement {
  static styles = css`
    .avatar-group webmodule-avatar:not(:first-of-type) {
      margin-left: -1rem;
    }
    
    .avatar-group webmodule-avatar::part(base) {
      border: solid 2px var(--webmodule-color-neutral-0);
    }
  `;

  @state() userNames: NotifyUser[] = [];

  private _users: string[] = [];

  public get users(): string[] {
    return this._users;
  }

  @property({ type: Array })
  public set users(value: string[]) {
    this._users = value;
    this.updateUserNames();
  }

  @property({type: Boolean, reflect: true})
  allowadd: boolean = false;

  private async updateUserNames() {
    const names = await cache().userProfile.getMany(this.users);
       this.userNames = names?.map(x=> ({initials:getInitials(x.displayValue), email:x.data.emailAddress ?? ""})) ?? [];
  }

  private addUserRequested() {
    this.dispatchEvent(new CustomEvent('wm-add-user-requested', {
      bubbles: true,
      composed: true,
    }));
  }

  render(){
    return html`
      <p>Notify:</p>
      <div class="avatar-group">
        ${repeat(this.userNames, user => user.email, user => html`
          <webmodule-avatar initials="${user.initials}" label="${user.email}" style="--size: 2rem"></webmodule-avatar>
        `)}

        ${when(this.allowadd, () => html`
          <webmodule-avatar style="--size: 2rem" @click="${this.addUserRequested}">
            <webmodule-icon slot="icon" library="fa" name="fas-plus"></webmodule-icon>
          </webmodule-avatar>
        `)}
      </div>
    `;
  }
}
