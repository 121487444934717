import { classMap } from 'lit/directives/class-map.js';
import { defaultValue } from '../../common/default-value.js';
import { HasSlotController } from '../../common/slot-controller.js';
import { html } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
import { live } from 'lit/directives/live.js';
import { monitor } from '../../common/monitor.js';
import { property, query, state } from 'lit/decorators.js';
import componentStyles from '../../styles/component.styles.js';
import formControlStyles from '../../styles/form-control.styles.js';
import styles from './textarea.styles.js';
import WebModuleElement from '../../common/webmodule-element.js';
import type { CSSResultGroup } from 'lit';

/**
 * @summary Textarea's collect data from the user and allow multiple lines of text.
 *
 * @slot label - The textarea's label. Alternatively, you can use the `label` attribute.
 *
 * @event webmodule-blur - Emitted when the control loses focus.
 * @event webmodule-change - Emitted when an alteration to the control's value is committed by the user.
 * @event webmodule-focus - Emitted when the control gains focus.
 * @event webmodule-input - Emitted when the control receives input.
 *
 * @csspart form-control - The form control that wraps the label, input, and help text.
 * @csspart form-control-label - The label's wrapper.
 * @csspart form-control-input - The input's wrapper.
 * @csspart base - The component's base wrapper.
 * @csspart textarea - The internal `<textarea>` control.
 *
 * @tag webmodule-textarea
 */
export default class WebmoduleTextarea extends WebModuleElement {
  static styles: CSSResultGroup = [componentStyles, formControlStyles, styles];
  @query('.textarea__control') input: HTMLTextAreaElement;
  /** The number of rows to display by default. */
  @property({ type: Number }) rows = 4;
  /** Controls how the textarea can be resized. */
  @property() resize: 'none' | 'vertical' | 'auto' = 'vertical';
  @property() title = '';
  /** The name of the textarea, submitted as a name/value pair with form data. */
  @property() name = '';
  /** The current value of the textarea, submitted as a name/value pair with form data. */
  @property() value = '';
  /** The textarea's size. */
  @property({ reflect: true }) size: 'small' | 'medium' | 'large' = 'medium';
  /** Disables the textarea. */
  @property({ type: Boolean, reflect: true }) disabled = false;
  /** Makes the textarea a required field. */
  @property({ type: Boolean, reflect: true }) required = false;
  /** The textarea's label. If you need to display HTML, use the `label` slot instead. */
  @property() label = '';
  /** Makes the textarea readonly. */
  @property({ type: Boolean, reflect: true }) readonly = false;
  /** Draws a filled textarea. */
  @property({ type: Boolean, reflect: true }) filled = false;
  /** Placeholder text to show as a hint when the input is empty. */
  @property() placeholder = '';
  /** Controls whether and how text input is automatically capitalized as it is entered by the user. */
  @property() autocapitalize: 'off' | 'none' | 'on' | 'sentences' | 'words' | 'characters';
  /** Indicates whether the browser's autocorrect feature is on or off. */
  @property() autocorrect: string;
  /** Indicates that the input should receive focus on page load. */
  @property({ type: Boolean }) autofocus: boolean;
  /** Enables spell checking on the textarea. */
  @property({
    type: Boolean,
    converter: {
      // Allow "true|false" attribute values but keep the property boolean
      fromAttribute: value => (!value || value === 'false' ? false : true),
      toAttribute: value => (value ? 'true' : 'false')
    }
  })
  spellcheck = true;
  /**
   * Specifies what permission the browser has to provide assistance in filling out form field values. Refer to
   * [this page on MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete) for available values.
   */
  @property() autocomplete: string;
  /** The default value of the form control. Primarily used for resetting the form control. */
  @defaultValue() defaultValue = '';
  /** Used to customize the label or icon of the Enter key on virtual keyboards. */
  @property() enterkeyhint: 'enter' | 'done' | 'go' | 'next' | 'previous' | 'search' | 'send';
  /**
   * Tells the browser what type of data will be entered by the user, allowing it to display the appropriate virtual
   * keyboard on supportive devices.
   */
  @property() inputmode: 'none' | 'text' | 'decimal' | 'numeric' | 'tel' | 'search' | 'email' | 'url';
  /** The minimum length of input that will be considered valid. */
  @property({ type: Number }) minlength: number;
  /** The maximum length of input that will be considered valid. */
  @property({ type: Number }) maxlength: number;
  private readonly hasSlotController = new HasSlotController(this, 'label');
  private resizeObserver: ResizeObserver;
  @state() private hasFocus = false;

  connectedCallback() {
    super.connectedCallback();
    this.resizeObserver = new ResizeObserver(() => this.setTextareaHeight());

    this.updateComplete.then(() => {
      this.setTextareaHeight();
      this.resizeObserver.observe(this.input);
    });
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    if (this.input) {
      this.resizeObserver?.unobserve(this.input);
    }
  }

  @monitor('rows', { delayMonitorUntilFirstUpdate: true })
  handleRowsChange() {
    this.setTextareaHeight();
  }

  @monitor('value', { delayMonitorUntilFirstUpdate: true })
  async handleValueChange() {
    await this.updateComplete;
    this.setTextareaHeight();
  }

  focus(options?: FocusOptions) {
    this.input.focus(options);
  }

  blur() {
    this.input.blur();
  }

  select() {
    this.input.select();
  }

  scrollPosition(position?: { top?: number; left?: number }): { top: number; left: number } | undefined {
    if (position) {
      if (typeof position.top === 'number') this.input.scrollTop = position.top;
      if (typeof position.left === 'number') this.input.scrollLeft = position.left;
      return undefined;
    }

    return {
      top: this.input.scrollTop,
      left: this.input.scrollTop
    };
  }

  setSelectionRange(
    selectionStart: number,
    selectionEnd: number,
    selectionDirection: 'forward' | 'backward' | 'none' = 'none'
  ) {
    this.input.setSelectionRange(selectionStart, selectionEnd, selectionDirection);
  }

  setRangeText(
    replacement: string,
    start?: number,
    end?: number,
    selectMode: 'select' | 'start' | 'end' | 'preserve' = 'preserve'
  ) {
    const selectionStart = start ?? this.input.selectionStart;
    const selectionEnd = end ?? this.input.selectionEnd;

    this.input.setRangeText(replacement, selectionStart, selectionEnd, selectMode);

    if (this.value !== this.input.value) {
      this.value = this.input.value;
      this.setTextareaHeight();
    }
  }

  render() {
    const hasLabelSlot = this.hasSlotController.checkFor('label');
    const hasLabel = this.label ? true : !!hasLabelSlot;

    return html`
      <div
        part="form-control"
        class=${classMap({
          'form-control': true,
          'form-control--small': this.size === 'small',
          'form-control--medium': this.size === 'medium',
          'form-control--large': this.size === 'large',
          'form-control--has-label': hasLabel
        })}
      >
        <label
          part="form-control-label"
          class="form-control__label"
          for="input"
          aria-hidden=${hasLabel ? 'false' : 'true'}
        >
          <slot name="label">${this.label}</slot>
        </label>

        <div part="form-control-input" class="form-control-input">
          <div
            part="base"
            class=${classMap({
              textarea: true,
              'textarea--small': this.size === 'small',
              'textarea--medium': this.size === 'medium',
              'textarea--large': this.size === 'large',
              'textarea--standard': !this.filled,
              'textarea--filled': this.filled,
              'textarea--disabled': this.disabled,
              'textarea--focused': this.hasFocus,
              'textarea--empty': !this.value,
              'textarea--resize-none': this.resize === 'none',
              'textarea--resize-vertical': this.resize === 'vertical',
              'textarea--resize-auto': this.resize === 'auto'
            })}
          >
            <textarea
              part="textarea"
              id="input"
              class=${classMap({ textarea__control: true, short: this.classList.contains('short') })}
              title=${this.title}
              name=${ifDefined(this.name)}
              .value=${live(this.value)}
              ?disabled=${this.disabled}
              ?readonly=${this.readonly}
              ?required=${this.required}
              placeholder=${ifDefined(this.placeholder)}
              rows=${ifDefined(this.rows)}
              minlength=${ifDefined(this.minlength)}
              maxlength=${ifDefined(this.maxlength)}
              autocapitalize=${ifDefined(this.autocapitalize)}
              autocorrect=${ifDefined(this.autocorrect)}
              ?autofocus=${this.autofocus}
              spellcheck=${ifDefined(this.spellcheck)}
              enterkeyhint=${ifDefined(this.enterkeyhint)}
              inputmode=${ifDefined(this.inputmode)}
              @change=${this.handleChange}
              @input=${this.handleInput}
              @focus=${this.handleFocus}
              @blur=${this.handleBlur}
            ></textarea>
          </div>
        </div>
      </div>
    `;
  }

  private handleBlur() {
    this.hasFocus = false;
    this.emit('webmodule-blur');
  }

  private handleChange() {
    this.value = this.input.value;
    this.setTextareaHeight();
    this.emit('webmodule-change');
  }

  private handleFocus() {
    this.hasFocus = true;
    this.emit('webmodule-focus');
  }

  private handleInput() {
    this.value = this.input.value;
    this.emit('webmodule-input');
  }

  private setTextareaHeight() {
    if (this.resize === 'auto') {
      this.input.style.height = 'auto';
      this.input.style.height = `${this.input.scrollHeight}px`;
    } else {
      (this.input.style.height as string | undefined) = undefined;
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'webmodule-textarea': WebmoduleTextarea;
  }
}
