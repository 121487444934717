import { classMap } from 'lit/directives/class-map.js';
import { defaultValue } from '../../common/default-value.js';
import { HasSlotController } from '../../common/slot-controller.js';
import { html } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
import { live } from 'lit/directives/live.js';
import { monitor } from '../../common/monitor.js';
import { property, query, state } from 'lit/decorators.js';
import componentStyles from '../../styles/component.styles.js';
import formControlStyles from '../../styles/form-control.styles.js';
import styles from './input.styles.js';
import WebModuleElement from '../../common/webmodule-element.js';
import type { CSSResultGroup } from 'lit';

/**
 * @summary Control to get input from user.
 *
 * @slot label - The input's label. Alternatively, you can use the `label` attribute.
 * @slot prefix - Used to prepend a presentational icon or similar element to the input.
 * @slot suffix - Used to append a presentational icon or similar element to the input.
 * @slot clear-icon - An icon to use in lieu of the default clear icon.
 * @slot show-password-icon - An icon to use in lieu of the default show password icon.
 * @slot hide-password-icon - An icon to use in lieu of the default hide password icon.
 *
 * @event webmodule-blur - Emitted when the control loses focus.
 * @event webmodule-change - Emitted when an alteration to the control's value is committed by the user.
 * @event webmodule-clear - Emitted when the clear button is activated.
 * @event webmodule-focus - Emitted when the control gains focus.
 * @event webmodule-input - Emitted when the control receives input.
 * @event webmodule-keydown - Emitted when the key is pressed
 * @event webmodule-keyup - Emitted when the key is released
 *
 * @csspart form-control - The form control that wraps the label, input, and help text.
 * @csspart form-control-label - The label's wrapper.
 * @csspart form-control-input - The input's wrapper.
 * @csspart base - The component's base wrapper.
 * @csspart input - The internal `<input>` control.
 * @csspart prefix - The container that wraps the prefix.
 * @csspart clear-button - The clear button.
 * @csspart password-toggle-button - The password toggle button.
 * @csspart suffix - The container that wraps the suffix.
 *
 * @tag webmodule-input
 */
export default class WebmoduleInput extends WebModuleElement {
  static styles: CSSResultGroup = [componentStyles, formControlStyles, styles];
  protected static _sharedInput: null | HTMLInputElement = null;
  @query('.input__control') input: HTMLInputElement;
  /**
   * The type of input. Works the same as a native `<input>` element, but only a subset of types is supported. Defaults
   * to `text`.
   */
  @property({ reflect: true }) type:
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'number'
    | 'password'
    | 'search'
    | 'tel'
    | 'text'
    | 'time'
    | 'url' = 'text';
  /** Draws a pill-style input with rounded edges. */
  @property({ type: Boolean, reflect: true }) pill = false;
  /** Adds a clear button when the input is not empty. */
  @property({ type: Boolean }) clearable = false;
  /** Adds a button to toggle the password's visibility. Only applies to password types. */
  @property({ attribute: 'password-toggle', type: Boolean }) passwordToggle = false;
  /** Determines whether the password is currently visible. Only applies to password input types. */
  @property({ attribute: 'password-visible', type: Boolean }) passwordVisible = false;
  /** Hides the browser's built-in increment/decrement spin buttons for number inputs. */
  @property({ attribute: 'no-spin-buttons', type: Boolean }) noSpinButtons = false;
  /** A regular expression pattern to validate input against. */
  @property() pattern: string;
  /** The input's minimum value. Only applies to date and number input types. */
  @property() min: number | string;
  /** The input's maximum value. Only applies to date and number input types. */
  @property() max: number | string;
  /**
   * Specifies the granularity that the value must adhere to, or the special value `any` which means no stepping is
   * implied, allowing any numeric value. Only applies to date and number input types.
   */
  @property() step: number | 'any';
  @property() title = '';
  /** The name of the input, submitted as a name/value pair with form data. */
  @property() name = '';
  /** The current value of the input, submitted as a name/value pair with form data. */
  @property() value = '';
  /** The input's size. */
  @property({ reflect: true }) size: 'small' | 'medium' | 'large' = 'medium';
  /** Disables the input. */
  @property({ type: Boolean, reflect: true }) disabled = false;
  /** Makes the input a required field. */
  @property({ type: Boolean, reflect: true }) required = false;
  /** The input's label. If you need to display HTML, use the `label` slot instead. */
  @property() label = '';
  /** Makes the input readonly. */
  @property({ type: Boolean, reflect: true }) readonly = false;
  /** Draws a filled input. */
  @property({ type: Boolean, reflect: true }) filled = false;
  /** show as uppercase. */
  @property({ type: Boolean, reflect: true }) uppercase = false;
  /** Placeholder text to show as a hint when the input is empty. */
  @property() placeholder = '';
  /** Controls whether and how text input is automatically capitalized as it is entered by the user. */
  @property() autocapitalize: 'off' | 'none' | 'on' | 'sentences' | 'words' | 'characters';
  /** Indicates whether the browser's autocorrect feature is on or off. */
  @property() autocorrect: string;
  /** Indicates that the input should receive focus on page load. */
  @property({ type: Boolean }) autofocus: boolean;
  /** Enables spell checking on the input. */
  @property({
    type: Boolean,
    converter: {
      // Allow "true|false" attribute values but keep the property boolean
      fromAttribute: value => (!value || value === 'false' ? false : true),
      toAttribute: value => (value ? 'true' : 'false')
    }
  })
  spellcheck = true;
  /**
   * Specifies what permission the browser has to provide assistance in filling out form field values. Refer to
   * [this page on MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete) for available values.
   */
  @property() autocomplete: string = 'off';
  /** The default value of the form control. Primarily used for resetting the form control. */
  @defaultValue() defaultValue = '';
  /** Used to customize the label or icon of the Enter key on virtual keyboards. */
  @property() enterkeyhint: 'enter' | 'done' | 'go' | 'next' | 'previous' | 'search' | 'send';
  /**
   * Tells the browser what type of data will be entered by the user, allowing it to display the appropriate virtual keyboard
   */
  @property() inputmode: 'none' | 'text' | 'decimal' | 'numeric' | 'tel' | 'search' | 'email' | 'url';
  /** The minimum length of input that will be considered valid. */
  @property({ type: Number }) minlength: number;
  /** The maximum length of input that will be considered valid. */
  @property({ type: Number }) maxlength: number;
  private readonly hasSlotController = new HasSlotController(this, 'label');
  @state() private hasFocus = false;

  //
  // Using in-memory input getters/setters instead of the one in the template since the properties can be set before the component is rendered.
  //

  get valueAsDate() {
    this._dateInput.type = this.type;
    this._dateInput.value = this.value;
    return this.input?.valueAsDate || this._dateInput.valueAsDate;
  }

  set valueAsDate(newValue: Date | null) {
    this._dateInput.type = this.type;
    this._dateInput.valueAsDate = newValue;
    this.value = this._dateInput.value;
  }

  /** Gets or sets the current value as a number. Returns `NaN` if the value can't be converted. */
  get valueAsNumber() {
    this._numberInput.value = this.value;
    return this.input?.valueAsNumber || this._numberInput.valueAsNumber;
  }

  set valueAsNumber(newValue: number) {
    this._numberInput.valueAsNumber = newValue;
    this.value = this._numberInput.value;
  }

  private get _numberInput() {
    const ctor = this.constructor as unknown as typeof WebmoduleInput;
    if (ctor._sharedInput === null) ctor._sharedInput = document.createElement('input');

    const input = ctor._sharedInput;
    input.type = 'number';
    return input;
  }

  private get _dateInput() {
    const ctor = this.constructor as unknown as typeof WebmoduleInput;
    if (ctor._sharedInput === null) ctor._sharedInput = document.createElement('input');

    const input = ctor._sharedInput;
    input.type = 'date';
    return input;
  }

  @monitor('step', { delayMonitorUntilFirstUpdate: true })
  handleStepChange() {
    this.input.step = String(this.step);
  }

  @monitor('value', { delayMonitorUntilFirstUpdate: true })
  async handleValueChange() {
    await this.updateComplete;
  }

  focus(options?: FocusOptions) {
    this.input.focus(options);
  }

  blur() {
    this.input.blur();
  }

  select() {
    this.input.select();
  }

  setSelectionRange(
    selectionStart: number,
    selectionEnd: number,
    selectionDirection: 'forward' | 'backward' | 'none' = 'none'
  ) {
    this.input.setSelectionRange(selectionStart, selectionEnd, selectionDirection);
  }

  setRangeText(
    replacement: string,
    start?: number,
    end?: number,
    selectMode: 'select' | 'start' | 'end' | 'preserve' = 'preserve'
  ) {
    const selectionStart = start ?? this.input.selectionStart!;
    const selectionEnd = end ?? this.input.selectionEnd!;

    this.input.setRangeText(replacement, selectionStart, selectionEnd, selectMode);

    if (this.value !== this.input.value) {
      this.value = this.input.value;
    }
  }

  /** Displays the browser picker for an input element (only works if the browser supports it for the input type). */
  showPicker() {
    if ('showPicker' in HTMLInputElement.prototype) {
      this.input.showPicker();
    }
  }

  stepUp() {
    this.input.stepUp();
    if (this.value !== this.input.value) {
      this.value = this.input.value;
    }
  }

  stepDown() {
    this.input.stepDown();
    if (this.value !== this.input.value) {
      this.value = this.input.value;
    }
  }

  render() {
    const hasLabelSlot = this.hasSlotController.checkFor('label');
    const hasLabel = this.label ? true : !!hasLabelSlot;
    const hasClearIcon = this.clearable && !this.disabled && !this.readonly;
    const isClearIconVisible = hasClearIcon && (typeof this.value === 'number' || this.value.length > 0);

    return html`
      <div
        part="form-control"
        class=${classMap({
          'form-control': true,
          'form-control--small': this.size === 'small',
          'form-control--medium': this.size === 'medium',
          'form-control--large': this.size === 'large',
          'form-control--has-label': hasLabel
        })}
      >
        <label
          part="form-control-label"
          class="form-control__label"
          for="input"
          aria-hidden=${hasLabel ? 'false' : 'true'}
        >
          <slot name="label">${this.label}</slot>
        </label>

        <div part="form-control-input" class="form-control-input">
          <div
            part="base"
            class=${classMap({
              input: true,

              // Sizes
              'input--small': this.size === 'small',
              'input--medium': this.size === 'medium',
              'input--large': this.size === 'large',

              // States
              'input--pill': this.pill,
              'input--standard': !this.filled,
              'input--filled': this.filled,
              'input--disabled': this.disabled,
              'input--focused': this.hasFocus,
              'input--empty': !this.value,
              'input--no-spin-buttons': this.noSpinButtons
            })}
          >
            <span part="prefix" class="input__prefix">
              <slot name="prefix"></slot>
            </span>

            <input
              part="input"
              id="input"
              class=${classMap({ input__control: true, 'input--uppercase': this.uppercase })}
              type=${this.type === 'password' && this.passwordVisible ? 'text' : this.type}
              title=${this.title}
              name=${ifDefined(this.name)}
              ?disabled=${this.disabled}
              ?readonly=${this.readonly}
              ?required=${this.required}
              placeholder=${ifDefined(this.placeholder)}
              minlength=${ifDefined(this.minlength)}
              maxlength=${ifDefined(this.maxlength)}
              min=${ifDefined(this.min)}
              max=${ifDefined(this.max)}
              step=${ifDefined(this.step as number)}
              .value=${live(this.value)}
              autocapitalize=${ifDefined(this.autocapitalize)}
              autocomplete=${ifDefined(this.autocomplete)}
              autocorrect=${ifDefined(this.autocorrect)}
              ?autofocus=${this.autofocus}
              spellcheck=${this.spellcheck}
              pattern=${ifDefined(this.pattern)}
              enterkeyhint=${ifDefined(this.enterkeyhint)}
              inputmode=${ifDefined(this.inputmode)}
              @change=${this.handleChange}
              @input=${this.handleInput}
              @focus=${this.handleFocus}
              @blur=${this.handleBlur}
              @keydown=${this.handleKeyDown}
              @keyup=${this.handleKeyUp}
            />

            ${isClearIconVisible
              ? html`
                  <button
                    part="clear-button"
                    class="input__clear"
                    type="button"
                    aria-label="Clear input"
                    @click=${this.handleClearClick}
                    tabindex="-1"
                  >
                    <slot name="clear-icon">
                      <webmodule-icon name="x-circle-fill"></webmodule-icon>
                    </slot>
                  </button>
                `
              : ''}
            ${this.passwordToggle && !this.disabled
              ? html`
                  <button
                    part="password-toggle-button"
                    class="input__password-toggle"
                    type="button"
                    aria-label=${this.passwordVisible ? 'Hide Password' : 'Show Password'}
                    @click=${this.handlePasswordToggle}
                    tabindex="-1"
                  >
                    ${this.passwordVisible
                      ? html`
                          <slot name="show-password-icon">
                            <webmodule-icon name="eye-slash"></webmodule-icon>
                          </slot>
                        `
                      : html`
                          <slot name="hide-password-icon">
                            <webmodule-icon name="eye"></webmodule-icon>
                          </slot>
                        `}
                  </button>
                `
              : ''}

            <span part="suffix" class="input__suffix">
              <slot name="suffix"></slot>
            </span>
          </div>
        </div>
      </div>
    `;
  }

  private handleBlur() {
    this.hasFocus = false;
    this.emit('webmodule-blur');
  }

  private handleChange() {
    this.value = this.input.value;
    this.emit('webmodule-change');
  }

  private handleClearClick(event: MouseEvent) {
    event.preventDefault();

    if (this.value !== '') {
      this.value = '';
      this.emit('webmodule-clear');
      this.emit('webmodule-input');
      this.emit('webmodule-change');
    }

    this.input.focus();
  }

  private handleFocus() {
    this.hasFocus = true;
    this.emit('webmodule-focus');
  }

  private handleInput() {
    this.value = this.input.value;
    this.emit('webmodule-input');
  }

  private handlePasswordToggle() {
    this.passwordVisible = !this.passwordVisible;
  }

  private handleKeyDown(event: KeyboardEvent) {
    this.emit('webmodule-keydown', { detail: event });
  }

  private handleKeyUp(event: KeyboardEvent) {
    this.emit('webmodule-keyup', { detail: event });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'webmodule-input': WebmoduleInput;
  }
}
