import { emptyGuid } from '../api/guid.js';
import { tlang } from '../language/lang.js';

export function validId(value: string | null | undefined): boolean {
  return !isEmptyOrSpace(value);
}

export function removeDuplicatIds(arr: string[]): string[] {
  return [...new Set(arr)];
}

export function sameText(a: string | undefined, ...b: (string | undefined)[]): boolean {
  for (const item of b) {
    if (a?.toLowerCase() === item?.toLowerCase()) return true;
  }
  return false;
}

export function containsText(value: string, searchTerm: string): boolean {
  return value.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase());
}

export function sameAs(a: any, ...b: any[]): boolean {
  return b.some(item => item === a);
}

export function isEmptyOrSpace(value: string | null | undefined): boolean {
  return value === null || value === undefined || value.trim() === '' || value.trim() == emptyGuid;
}

export function hasContent(value: string | null | undefined): boolean {
  return !isEmptyOrSpace(value);
}

export function joinWithCommaAnd(array: string[]) {
  if (array && array.length > 1) {
    const last = array.pop();
    return `${array.join(', ')} and ${last}`;
  } else {
    return array[0] || '';
  }
}

export function isValidEmail(mail: string): boolean {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail);
}

export function concatStrings(
  options: {
    split?: string;
    excludeEmpty?: boolean;
  },
  ...strings: (string | undefined)[]
): string {
  const seperator = options.split ?? '-';
  let result = '';
  strings.forEach(s => {
    if ((options.excludeEmpty ?? true) && isEmptyOrSpace(s)) return;
    const part = s ?? '';
    result = isEmptyOrSpace(result) ? part : `${result}${seperator}${part}`;
  });
  return result.trimEnd();
}

export function concatNotes(...args) {
  return args.filter(str => str && !isEmptyOrSpace(str.toString())).join('\r\n----------\r\n');
}

function displayBoolean(yes: string, no: string, value?: boolean) {
  return value ? yes : no;
}

export function displayYesNo(value?: boolean) {
  return displayBoolean(tlang`Yes`, tlang`No`, value);
}

export function bsFormRadioGroupYesNoOptions() {
  return [
    { value: 'Yes', text: 'Yes' },
    { value: 'No', text: 'No' }
  ];
}

export function firstValidString(...args) {
  for (let i = 0; i < args.length; i++) if (!isEmptyOrSpace(args[i])) return args[i];
  return '';
}

export function flagInSet(flag: number, flagSet: number): boolean {
  // eslint-disable-next-line no-bitwise
  return (flag & flagSet) !== 0;
}

export function isFlagInFilterSet(flag: number, flagSet?: number | null): boolean {
  return flagSet === undefined || flagSet === null || flagInSet(flag, flagSet);
}

export function getInitials(fullName: string): string {
  // Split the full name into individual words, excluding extra spaces
  const nameParts = fullName.trim().split(/\s+/);

  if (nameParts.length === 1) {
    // For single-word names, return the first initial
    return nameParts[0].charAt(0).toUpperCase();
  }

  // For multi-word names, use the first letter of the first and last name parts
  return nameParts[0].charAt(0).toUpperCase() + nameParts[nameParts.length - 1].charAt(0).toUpperCase();
}

/**
 * NOTE: This is a work-around what appears to be a bug in Chrome :https://bugs.chromium.org/p/chromium/issues/detail?id=920069
 */
export function getOffset(element: HTMLElement, parent: HTMLElement) {
  return {
    top: Math.round(element.getBoundingClientRect().top - parent.getBoundingClientRect().top),
    left: Math.round(element.getBoundingClientRect().left - parent.getBoundingClientRect().left)
  };
}

/*
(function () {
  let prefix: string | undefined = undefined;
  let main: string | undefined = 'test';
  let suffix: string | undefined = undefined;
  console.log(concatStrings({ split: ' - ', excludeEmpty: true }, prefix, main, suffix));
  prefix = 'AAA';
  main = 'test';
  suffix = undefined;
  console.log(concatStrings({ split: ' - ', excludeEmpty: true }, prefix, main, suffix));
  prefix = 'AAA';
  main = 'test';
  suffix = 'BBB';
  console.log(concatStrings({ split: ' - ', excludeEmpty: true }, prefix, main, suffix));
  prefix = 'AAA';
  main = undefined;
  suffix = 'BBB';
  console.log(concatStrings({ split: ' - ', excludeEmpty: true }, prefix, main, suffix));
})();
*/
