import { ContactSummaryPickerBrowser } from './contact-list-table';
import { html } from 'lit';
import { ModalPickerDialog, ModalPickerDialogOptions } from '../../../webmodule-common/other/ui/modal-picker-base';
import { NullPromise } from '../../../webmodule-common/other/ui/events';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { ViewContactSummary } from '../../api/dealer-api-interface-client';

interface ContactBrowserPickerOptions extends ModalPickerDialogOptions<ViewContactSummary> {
  clientId?: string;
}

export async function ContactPicker(clientId?: string): NullPromise<ViewContactSummary> {
  return new Promise<ViewContactSummary | null>((resolve, reject) => {
    const picker = new ContactBrowserPicker({
      onSelect: (viewContactSummary: ViewContactSummary | null) => {
        resolve(viewContactSummary);
      },
      // eslint-disable-next-line prefer-promise-reject-errors
      onCancel: () => resolve(null),
      title: tlang`Select a %%contact%%`,
      clientId: clientId
    });
    picker.showModal().then(() => resolve(null));
  });
}

class ContactBrowserPicker extends ModalPickerDialog<ViewContactSummary> {
  comms: ContactSummaryPickerBrowser;

  constructor(options: ContactBrowserPickerOptions) {
    super(options);
    this.comms = new ContactSummaryPickerBrowser({
      title: () => options.title ?? '',
      clientId: options.clientId,
      onContactSelect: contactSummary => this.contactSelected(contactSummary)
    });
  }

  bodyTemplate() {
    return html`${this.comms}`;
  }

  protected async contactSelected(contactSummary: ViewContactSummary): Promise<void> {
    this.selected = contactSummary;
    await this.select(contactSummary);
  }

  footerTemplate() {
    return html` <webmodule-button size="small" variant="default" @click=${() => this.hideModal()}
      >${tlang`Close`}
    </webmodule-button>`;
  }
}
