import { addV6DebugInformation, clearAllV6DebugInformation } from '../../../../quotes/v6/v6-debug-information.js';
import {
  AskConfirmation,
  confirmationButtons,
  ConfirmationButtonType
} from '../../../../../webmodule-common/other/ui/modal-confirmation.js';
import { ButtonCaptionProvider, QuoteDataEntryView } from '../../../../quotes/views/quote-view.js';
import { customElement } from 'lit/decorators.js';
import { DealerReport } from '../../../../api/dealer-api-interface-clientagent.js';
import { fireQuickInformationToast } from '../../../../../webmodule-common/other/toast-away.js';
import { FranchiseeQuoteDEView, FranchiseeQuoteView } from '../franchisee-quote-view.js';
import { getApiFactory } from '../../../../api/api-injector.js';
import { getQuoteTitleStr } from '../../../../quotes/data/quote-helper-functions.js';
import { tlang } from '../../../../../webmodule-common/other/language/lang.js';
import { v6Editors, v6SupportsVersion, v6VersionMap } from '../../../../v6config/v6config.js';
import { V6PickerFamilyButton } from '../../../../../webmodule-common/interop/webmodule-interop.js';

@customElement('wm-franchiseev6quotedeview')
export class FranchiseeV6QuoteDEView extends FranchiseeQuoteDEView {
  protected clientAgentApi = getApiFactory().clientAgent();
  frameButtons: (V6PickerFamilyButton | null)[] | null = null;

  buildFrameButtons(): (ButtonCaptionProvider | null)[] {
    return this.frameButtons ?? super.buildFrameButtons();
  }

  public override async generateReportV2(dealerReport: DealerReport): Promise<boolean> {
    if (
      await AskConfirmation(
        tlang`${'ref:report-generation-draft'}A Draft report is intended for internal use only, a **Draft** watermark will clearly identify that this is not the final **Issued** version of the quote. Only an **Issued** version of the quote should be signed by a customer as this version can only be generated when all configurations and pricing is valid.

Purchase Orders can only be generated and submitted to your manufacturer from an Issued/Approved Quote.

Do you wish to proceed with generating the draft report?
            `,
        confirmationButtons[ConfirmationButtonType.yesNo],
        undefined,
        tlang`Generate Draft Report`
      )
    ) {
      await this.clientAgentApi.generateReport({
        id: this.quote.id,
        dealerReport: dealerReport
      });

      fireQuickInformationToast(tlang`You will be emailed a link to the document when it is ready`);
    }

    return false;
  }

  async dispose() {
    await super.dispose();

    await clearAllV6DebugInformation();

    //override if needed
  }

  public async afterConstruction(): Promise<void> {
    await super.afterConstruction();
    await this.quoteContainerManager.needsQuote();
    await this.quoteContainerManager.needsQuoteItems();
    if (v6SupportsVersion(v6VersionMap.hasMultipleFrameAdd)) {
      this.frameButtons = await v6Editors().getFramePickerButtons(this.quoteContainerManager.quote.supplierId);
    }
    await addV6DebugInformation('FranchiseeV6QuoteDEView', {
      quoteId: this.quote.id,
      quoteTitle: getQuoteTitleStr(this.quote, this.quoteContainerManager.quoteState)
    });
  }
}

@customElement('wm-franchiseev6quoteview')
export class FranchiseeV6QuoteView extends FranchiseeQuoteView {
  protected createView(): QuoteDataEntryView {
    return new FranchiseeV6QuoteDEView(this.options, this);
  }
}
