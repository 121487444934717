import { WebModuleLitTable, WebModuleLitTablePaginator } from './table/lit-table.js';
import ComponentApp from '../internal/ComponentApp.js';
import WebmoduleAlert from './alert/alert.js';
import WebmoduleAnimate from './animate/animate.js';
import WebmoduleAvatar from './avatar/avatar.js';
import WebmoduleBadge from './badge/badge.js';
import WebmoduleBreadcrumb from './breadcrumb/breadcrumb.js';
import WebmoduleBreadcrumbItem from './breadcrumb-item/breadcrumb-item.js';
import WebmoduleButton from './button/button.js';
import WebmoduleButtonGroup from './button-group/button-group.js';
import WebmoduleCard from './card/card.js';
import WebmoduleCheckbox from './checkbox/checkbox.js';
import WebmoduleDetails from './details/details.js';
import WebmoduleDivider from './divider/divider.js';
import WebmoduleDrawer from './drawer/drawer.js';
import WebmoduleDropdown from './dropdown/dropdown.js';
import WebmoduleFormatDate from './format-date/format-date.js';
import WebmoduleFormatList from './format-list/format-list.js';
import WebmoduleFormatNumber from './format-number/format-number.js';
import WebmoduleFormatRelativeTime from './format-relative-time/format-relative-time.js';
import WebmoduleIcon from './icon/icon.js';
import WebmoduleIconButton from './icon-button/icon-button.js';
import WebmoduleInput from './input/input.js';
import WebmoduleInputMoney from './input-money/input-money.js';
import WebmoduleInputRow from './input-row/input-row.js';
import WebmoduleMenu from './menu/menu.js';
import WebmoduleMenuItem from './menu-item/menu-item.js';
import WebmoduleMenuLabel from './menu-label/menu-label.js';
import WebmoduleModal from './modal/modal.js';
import WebmoduleOption from './option/option.js';
import WebmodulePlaceholder from './placeholder/placeholder.js';
import WebmodulePopup from './popup/popup.js';
import WebmoduleQrCode from './qr-code/qr-code.js';
import WebmoduleRadio from './radio/radio.js';
import WebmoduleRadioButton from './radio-button/radio-button.js';
import WebmoduleRadioGroup from './radio-group/radio-group.js';
import WebmoduleSelect from './select/select.js';
import WebmoduleSpinner from './spinner/spinner.js';
import WebmoduleTab from './tab/tab.js';
import WebmoduleTabGroup from './tab-group/tab-group.js';
import WebmoduleTabPanel from './tab-panel/tab-panel.js';
import WebmoduleTag from './tag/tag.js';
import WebmoduleTestcomp from './testcomp/testcomp.js';
import WebmoduleTextarea from './textarea/textarea.js';
import WebmoduleToggle from './toggle/toggle.js';
import WebmoduleTooltip from './tooltip/tooltip.js';
import WebmoduleTree from './tree/tree.js';
import WebmoduleTreeItem from './tree-item/tree-item.js';

function safeRegister(name: string, constructor: CustomElementConstructor) {
  try {
    customElements.define(name, constructor);
  } catch {
    console.log(`customElement "${name}" already registered`);
  }
}

function prefixName(name: string, prefix?: string): string {
  if (prefix) prefix += '-';
  else prefix = 'webmodule-';
  return `${prefix}${name}`;
}

export function registerComponents(prefix?: string) {
  safeRegister(prefixName('checkbox', prefix), WebmoduleCheckbox);
  safeRegister(prefixName('spinner', prefix), WebmoduleSpinner);
  safeRegister(prefixName('badge', prefix), WebmoduleBadge);
  safeRegister(prefixName('breadcrumb', prefix), WebmoduleBreadcrumb);
  safeRegister(prefixName('breadcrumb-item', prefix), WebmoduleBreadcrumbItem);
  safeRegister(prefixName('card', prefix), WebmoduleCard);
  safeRegister(prefixName('placeholder', prefix), WebmodulePlaceholder);
  safeRegister(prefixName('divider', prefix), WebmoduleDivider);
  safeRegister(prefixName('icon', prefix), WebmoduleIcon);
  safeRegister(prefixName('qr-code', prefix), WebmoduleQrCode);
  safeRegister(prefixName('lit-table-paginator', prefix), WebModuleLitTablePaginator);
  safeRegister(prefixName('lit-table', prefix), WebModuleLitTable);
  safeRegister(prefixName('testcomp', prefix), WebmoduleTestcomp);
  safeRegister(prefixName('popup', prefix), WebmodulePopup);
  safeRegister(prefixName('animate', prefix), WebmoduleAnimate);
  safeRegister(prefixName('tooltip', prefix), WebmoduleTooltip);
  safeRegister(prefixName('toggle', prefix), WebmoduleToggle);
  safeRegister(prefixName('input', prefix), WebmoduleInput);
  safeRegister(prefixName('input-row', prefix), WebmoduleInputRow);
  safeRegister(prefixName('icon-button', prefix), WebmoduleIconButton);
  safeRegister(prefixName('button', prefix), WebmoduleButton);
  safeRegister(prefixName('tag', prefix), WebmoduleTag);
  safeRegister(prefixName('button-group', prefix), WebmoduleButtonGroup);
  safeRegister(prefixName('option', prefix), WebmoduleOption);
  safeRegister(prefixName('select', prefix), WebmoduleSelect);
  safeRegister(prefixName('menu-label', prefix), WebmoduleMenuLabel);
  safeRegister(prefixName('menu-item', prefix), WebmoduleMenuItem);
  safeRegister(prefixName('menu', prefix), WebmoduleMenu);
  safeRegister(prefixName('dropdown', prefix), WebmoduleDropdown);
  safeRegister(prefixName('tree-item', prefix), WebmoduleTreeItem);
  safeRegister(prefixName('tree', prefix), WebmoduleTree);
  safeRegister(prefixName('alert', prefix), WebmoduleAlert);
  safeRegister(prefixName('textarea', prefix), WebmoduleTextarea);
  safeRegister(prefixName('radio', prefix), WebmoduleRadio);
  safeRegister(prefixName('radio-button', prefix), WebmoduleRadioButton);
  safeRegister(prefixName('radio-group', prefix), WebmoduleRadioGroup);
  safeRegister(prefixName('modal', prefix), WebmoduleModal);
  safeRegister(prefixName('drawer', prefix), WebmoduleDrawer);
  safeRegister(prefixName('tab-panel', prefix), WebmoduleTabPanel);
  safeRegister(prefixName('tab', prefix), WebmoduleTab);
  safeRegister(prefixName('tab-group', prefix), WebmoduleTabGroup);
  safeRegister(prefixName('input-money', prefix), WebmoduleInputMoney);
  safeRegister(prefixName('format-number', prefix), WebmoduleFormatNumber);
  safeRegister(prefixName('format-date', prefix), WebmoduleFormatDate);
  safeRegister(prefixName('format-list', prefix), WebmoduleFormatList);
  safeRegister(prefixName('format-relative-time', prefix), WebmoduleFormatRelativeTime);
  safeRegister(prefixName('details', prefix), WebmoduleDetails);
  safeRegister(prefixName('avatar', prefix), WebmoduleAvatar);

  safeRegister('component-app', ComponentApp);
}
