import { css } from 'lit';

export default css`
  :host {
    /*
     * This for the "radio" control
     */
    --radio-display: block;
    //--element-inline-end-margin: 0;
    display: block;
  }

  .form-control {
    position: relative;
    border: none;
    padding: 0;
    margin: 0;
  }

  .form-control__label {
    padding: 0;
  }

  .radio-group--required .radio-group__label::after {
    content: var(--webmodule-control-required-content);
    margin-inline-start: var(--webmodule-control-required-content-offset);
  }

  .form-control--inline-radio {
    --radio-display: inline-block;
    //--element-inline-end-margin: 1em;
  }
`;
