import { classMap } from 'lit/directives/class-map.js';
import { html } from 'lit';
import { monitor } from '../../common/monitor.js';
import { property, state } from 'lit/decorators.js';
import componentStyles from '../../styles/component.styles.js';
import styles from './avatar.styles.js';
import WebModuleElement from '../../common/webmodule-element.js';
import type { CSSResultGroup } from 'lit';

/**
 * @summary Avatars are used to represent a person or object.
 *
 * @event webmodule-error - The image could not be loaded. This may because of an invalid URL,
 * a temporary network condition, or some unknown cause.
 *
 * @slot icon - The default icon to use when no image or initials are present. Works best with `<webmodule-icon>`.
 *
 * @csspart base - The component's base wrapper.
 * @csspart icon - The container that wraps the avatar's icon.
 * @csspart initials - The container that wraps the avatar's initials.
 * @csspart image - The avatar image. Only shown when the `image` attribute is set.
 *
 * @cssproperty --size - The size of the avatar.
 *
 * @tag webmodule-avatar
 */
export default class WebmoduleAvatar extends WebModuleElement {
  static styles: CSSResultGroup = [componentStyles, styles];
  /** The image url to use for the avatar. */
  @property() image = '';
  /** A label to use for the avatar. */
  @property() label = '';
  /** Initials to use as a fallback if there is no image set (1-2 characters max recommended). */
  @property() initials = '';
  /** Indicates how the browser should load the image. */
  @property() loading: 'eager' | 'lazy' = 'eager';
  /** Render shape of the avatar. */
  @property({ reflect: true }) shape: 'circle' | 'square' | 'rounded' = 'circle';
  @state() private hasError = false;

  @monitor('image')
  handleImageChange() {
    this.hasError = false;
  }

  render() {
    const avatarWithImage = html`
      <img
        part="image"
        class="avatar__image"
        src="${this.image}"
        loading="${this.loading}"
        alt=""
        @error="${this.handleImageLoadError}"
      />
    `;

    let avatarWithoutImage = html``;

    if (this.initials) {
      avatarWithoutImage = html` <div part="initials" class="avatar__initials">${this.initials}</div>`;
    } else {
      avatarWithoutImage = html`
        <div part="icon" class="avatar__icon" aria-hidden="true">
          <slot name="icon">
            <webmodule-icon name="person"></webmodule-icon>
          </slot>
        </div>
      `;
    }

    return html`
      <div
        part="base"
        class=${classMap({
          avatar: true,
          'avatar--circle': this.shape === 'circle',
          'avatar--rounded': this.shape === 'rounded',
          'avatar--square': this.shape === 'square'
        })}
        role="img"
        aria-label=${this.label}
      >
        ${this.image && !this.hasError ? avatarWithImage : avatarWithoutImage}
      </div>
    `;
  }

  private handleImageLoadError() {
    this.hasError = true;
    this.emit('webmodule-error');
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'webmodule-avatar': WebmoduleAvatar;
  }
}
